<template>
  <TopHeader></TopHeader>
  <Navbar></Navbar>
  <router-view />
  <Footer></Footer>
</template>

<script>
import TopHeader from "./components/TopHeader.vue";
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "app",
  components: {
    TopHeader,
    Navbar,
    Footer,
  },
};
</script>

<style lang="scss">
.slideout-content {
  background: #3f3a64;
}

.img-fluid {
  max-height: 738px;
}

.reg-btn {
  top: 200px;
  right: 210px;
  background: #f96434;
  border: none;
  padding: 10px 15px;
  width: 150px;
  border-radius: 10px;
  text-align: center;
  color: #fff;
  font-family: IranSans;
}

@media screen and (min-width: 800px) {
  h1 {
    font-weight: 900;
    font-size: 3rem;
    white-space: nowrap;
  }
  h6 {
    font-size: 1.2rem !important;
    /* white-space: nowrap !important; */
  }
  .mt-100 {
    margin-top: 100px !important;
  }
}
</style>
