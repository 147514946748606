<template>
  <Heading thisRouteName="فروشگاه ما"></Heading>
  <div class="container-fluid px-4" dir="rtl">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4 col-lg-3 mt-4">
          <sidebar ref="sideBar" @sortBy="sortBy"></sidebar>
        </div>
        <div class="col-12 col-md-8 col-lg-9 mt-4">
          <div class="row d-flex justify-content-between">
            <div class="col-6">
              <select
                v-model="selectedFilter"
                class="form-select"
                aria-label="Default select example"
              >
                <!-- <option
                  @click="sortBy('popularity')"
                  value="popularity"
                  selected
                >
                  {{ filterBy }} محبوبیت
                </option> -->
                <!-- <option @click="sortBy('rates')" value="rates">
                  {{ filterBy }} میانگین امتیاز
                </option> -->
                <option value="" selected>مرتب سازی</option>
                <option @click="sortBy('id', 'desc')" value="latest">
                  {{ filterBy }} آخرین
                </option>
                <option @click="sortBy('price', 'asc')" value="ascPrice">
                  {{ filterBy }} قیمت: کم به زیاد
                </option>
                <option @click="sortBy('price', 'desc')" value="descPrice">
                  {{ filterBy }} قیمت: زیاد به کم
                </option>
              </select>
            </div>
            <div class="col-6 text-start">
              <span> ما {{ productsCount }} محصول برای شما پیدا کردیم </span>
            </div>
          </div>
          <div class="row mt-4 mb-250">
            <div
              v-for="(product, index) in products"
              :key="index"
              class="col-6 col-md-4 col-lg-3"
            >
              <div class="product-card">
                <div class="product-card-header relative">
                  <img
                    @click="goToProductPage(product)"
                    :src="product.image"
                    class="w-100 image-product"
                    alt=""
                  />
                </div>
                <div class="product-card-body text-center">
                  <a @click="addToCart(product)" class="btn mt-3 btn-info">
                    <span class="text-white">افزودن به سبد خرید</span>
                  </a>
                  <h3 class="mt-3">
                    {{ product.price.toLocaleString() }} تومان
                  </h3>
                  <p class="mt-3">{{ product.title }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "@/components/Heading.vue";
import sidebar from "@/components/store/sidebar.vue";
import notification from "@/mixins/notification.js";
import errorReturner from "@/mixins/errorReturner.js";
import pusher from "../pusher";

export default {
  components: {
    Heading,
    sidebar,
  },
  mixins: [errorReturner, notification],
  data() {
    return {
      products: [],
      selectedFilter: "popularity",
    };
  },
  computed: {
    filterBy() {
      return "مرتب سازی براساس: ";
    },
    productsCount() {
      return 4;
    },
  },
  mounted() {
    this.$store.dispatch("getAllProducts").then((response) => {
      this.products = response.data;
    });

    let channel = pusher.subscribe("my-channel");

    let self = this;
    channel.bind(
      "App\\Events\\Broadcasts\\ProductStockChanged",
      function (data) {
        data.data.forEach((el) => {
          self.products.forEach((element) => {
            if (+el.id === +element.id) {
              element.in_stock = el.stock;
            }
          });
        });
      }
    );
  },
  methods: {
    goToProductPage(product) {
      this.$store.commit("SET_PRODUCT", product);
      localStorage.setItem("current_product", JSON.stringify(product));
      this.$router.push({
        name: "product",
        params: { id: product.id },
      });
    },
    sortBy(filterBy, sort) {
      this.products = this.products.sort((a, b) => {
        if (sort == "asc") {
          if (a[filterBy] < b[filterBy]) {
            return -1;
          }
          if (a[filterBy] > b[filterBy]) {
            return 1;
          }
          return 0;
        } else {
          if (a[filterBy] < b[filterBy]) {
            return 1;
          }
          if (a[filterBy] > b[filterBy]) {
            return -1;
          }
          return 0;
        }
      });
      this.selectedFilter = filterBy;
      this.$refs.sideBar.setSort(filterBy);
    },
    addToCart(product) {
      if (this.$store.state.cart.count >= 5) {
        this.errorNotification(
          this.errorMaker({
            response: {
              data: {
                message: "ظرفیت سبد خرید شما 5 محصول است",
              },
            },
          })
        );
        return;
      }

      let cart = this.$store.state.cart;

      let foundProduct = cart.products.find((el) => {
        return el.id === product.id;
      });

      if (!product.in_stock) {
        this.errorNotification(
          this.errorMaker({
            response: {
              data: {
                message: "بیش از این مقدار در انبار موجود نیست",
              },
            },
          })
        );
        return;
      }

      if (foundProduct) {
        if (foundProduct.qty >= product.in_stock) {
          this.errorNotification(
            this.errorMaker({
              response: {
                data: {
                  message: "بیش از این مقدار در انبار موجود نیست",
                },
              },
            })
          );
          return;
        }
      }

      this.$store.commit("ADD_TO_CART", product);
      this.successNotification({
        message: "باموفقیت به سبد خرید افزوده شد ",
      });
      this.$router.push({ name: "cart" });
    },
  },
};
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
}
.image-product {
  cursor: pointer;
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.shopCart {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.product-card-header:hover .image {
  opacity: 0.3;
}

.product-card-header:hover .shopCart {
  opacity: 1;
}

.shopCart .shopCartButton {
  background-color: #fff;
  color: #000;
  font-size: 20px;
  padding: 20px 25px;
  cursor: pointer;
  border-radius: 50%;
}

.shopCartButton:hover {
  background-color: #0071dc;
  color: #fff;
  font-size: 20px;
  padding: 20px 25px;
  cursor: pointer;
  border-radius: 50%;
}

.shopCart:hover > .shopCartButton::after {
  content: "افزودن به سبد خرید";
  color: #fff;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 14px;
  white-space: nowrap;
  border-radius: 5px;
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translate(-50%, -70%);
  background: #0071dc;
}

.shopCartButton i {
  vertical-align: middle;
}

.form-select {
  -moz-padding-start: 40px !important;
}

.mb-250 {
  margin-bottom: 250px;
}
</style>
