export default {
  methods: {
    successNotification(response) {
      this.$moshaToast(
        {
          title: "موفق",
          description: response.message,
        },
        {
          type: "success",
          transition: "zoom",
          position: "top-left",
          timeout: 5000,
        }
      );
    },
    errorNotification(error) {
      this.$moshaToast(
        {
          title: "خطا",
          description: error,
        },
        {
          type: "danger",
          transition: "zoom",
          position: "top-left",
          timeout: 5000,
        }
      );
    },
  },
};
