<template>
  <slideout
    dir="rtl"
    size="80%"
    @closing="onClosing"
    :arrow-button="false"
    :show-close="true"
    v-model="menuIsOpen"
  >
    <div class="row" dir="rtl">
      <div class="col-12 mt-4 align-items-center d-flex justify-content-around">
        <img height="60" src="@/assets/images/logo.png" alt="" />
        <i @click="closeMenu" class="fa fa-window-close"></i>
      </div>
    </div>
    <div class="row bg-menu mt-3 p-4" dir="rtl">
      <div class="col-12 d-flex side-menu-parent justify-content-center">
        <a class="parentlink"
          ><router-link @click="closeMenu" class="side-menu-link" to="/"
            >صفحه اصلی</router-link
          ></a
        >
      </div>
      <div class="col-12 d-flex side-menu-parent justify-content-center">
        <a class="parentlink"
          ><router-link @click="closeMenu" class="side-menu-link" to="/store"
            >خرید آیپی ثابت</router-link
          ></a
        >
      </div>
      <div class="col-12 d-flex side-menu-parent justify-content-center">
        <a class="parentlink"
          ><span @click="openTutorials('v2rayng')" class="side-menu-link"
            >آموزش اندروید</span
          ></a
        >
      </div>
      <div class="col-12 d-flex side-menu-parent justify-content-center">
        <a class="parentlink"
          ><span @click="openTutorials('shadowrocket')" class="side-menu-link"
            >آموزش آیفون</span
          ></a
        >
      </div>
      <div class="col-12 d-flex side-menu-parent justify-content-center">
        <a class="parentlink"
          ><span @click="openTutorials('clash')" class="side-menu-link"
            >آموزش دسکتاپ</span
          ></a
        >
      </div>
      <div class="col-12 d-flex side-menu-parent justify-content-center">
        <a class="parentlink"
          ><span @click="openModalSupport" class="side-menu-link"
            >پشتیبانی</span
          ></a
        >
      </div>
      <!-- <div class="col-12 d-flex side-menu-parent justify-content-center">
        <a class="parentlink"
          ><span @click="openModalBuyTut" class="side-menu-link"
            >آموزش خرید</span
          ></a
        >
      </div> -->
    </div>
  </slideout>
</template>

<script>
export default {
  data() {
    return {
      menuIsOpen: false,
    };
  },
  computed: {
    sideMenuOpen() {
      return this.menuIsOpen;
    },
  },
  emits: [ 'openModalSupport'],
  methods: {
    openTutorials(app) {
      window.open(`https://wiki.cloakify.site`, "_blank");
    },
    openMenu() {
      this.menuIsOpen = true;
    },
    closeMenu() {
      this.menuIsOpen = false;
    },
    onClosing() {},
    openModalSupport() {
      this.closeMenu();
      this.$emit("openModalSupport");
    },
    openModalBuyTut() {
      this.closeMenu();
      this.$emit("openModalBuyTut");
    },
  },
};
</script>

<style lang="scss" scoped>
.side-menu-parent {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding-bottom: 10px;
  padding-top: 10px;
}
.side-menu-link {
  color: #fff !important;
  font-size: 20px;
}
.bg-menu {
  background: #3f3a64;
  color: #fff;
}

.fa-window-close {
  font-size: 20px;
  vertical-align: middle;
  cursor: pointer;
}
</style>
