<template>
  <div
    id="supportModal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header d-flex justify-content-center">
          <h4 class="modal-title">اطلاعیه پشتیبانی</h4>
        </div>

        <!-- Modal body -->
        <div
          style="text-align: right; direction: rtl !important"
          class="modal-body"
        >
          <p>
            برای پشتیبانی فقط از طریق کانال تلگرام به آدرس
            <a href="https://t.me/cloakify">t.me/cloakify</a>
            اقدام کنید زمان تقریبی پاسخ گویی : بین 2 تا 4 ساعت
          </p>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer" style="margin: 0; width: 100% !important">
          <button
            type="button"
            class="btn btn-success h-60 w-100"
            data-bs-dismiss="modal"
          >
            باشه
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style scoped>
.modal-content {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
</style>
