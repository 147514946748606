<template>
  <div
    id="homeModal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header d-flex justify-content-center">
          <h4 class="modal-title">اطلاعیه سرورها</h4>
        </div>

        <!-- Modal body -->
        <div
          style="text-align: right; direction: rtl !important"
          class="modal-body"
        >
          <div class="old">
            <p>
              #آپدیت
              <br />
              آپدیت جدید شبکه انجام شد. ✔️🕯🔔
              <br />
              اسامی سرور های قدیمی تغییر کرده و راهنمای جدید سرور ها به شرح زیر
              میباشد: در ابتدا نام کوتاه کشور قرار داده است (برای مثال NL,CH و
              ....)
              <br />
              🟢 : سرور هایی که روبروشون این علامت قرار داره ترافیکشون روی شبکه
              داخلی کشور به صورت نیم بها محاسبه میشه. برای مثال اگر یه بسته 10
              گیگ ایرانسل خریدید میتونید رو این سرور ها تا نزدیک به 20 گیگ ازش
              استفاده کنید. 🌐
              <br />
              💠: سرور های معمولی با قابلیت دور زدن محدودیت های شبانه همراه اول
              و بقیه محدودیت های شبکه داخلی، استیبل ترین سرور ها در حال حاضر 🌐
              <br />
              ⬇️: سرور های مخصوص دانلود، روی این سرور ها مصرف ترافیک شما روی
              شبکه ما یک پنجم حالت عادی حساب میشه. 🌐
              <br />
              همچنین ترافیک روی سرور های مخصوص دانلود که با علامت ⬇️ مشخص شده
              اند از ساعت 12 شب تا 9 صبح رایگانه. 🕯
              <br />
              در حال حاضر شما عملا با خرید سرویس های کلوکیفای یه سرویس همه کاره
              میخرید که:
              <br />
              - تعداد کاربر نا محدود دارید ✔️
              <br />
              - سرور های مخصوص دانلود دارید که با مدیریت درست میتونید هرچقدر
              خواستید دانلود کنید (عملا سرویس نا محدود) ✔️
              <br />
              - دسترسی به سرور های نیم بها و .... ✔️
              <br />
              در صورت داشتن سوالات بیشتر با پشتیبانی در تماس باشید
            </p>
            <div style="text-align: left !important; direction: ltr !important">
              <br />
              🌐 Website :
              <a target="_blank" href="https://cloakify.site/"
                >https://cloakify.site/</a
              >
              <br />
              🎧  Support Bot :
              <a target="_blank" href="https://t.me/Cloakify_Bot">
                @Cloakify_Bot
              </a>
            </div>
            <p class="mt-2">
              برای آخرین اخبار عضو کانال ما باشید:
              <a target="_blank" href="https://t.me/cloakify">@cloakify</a>
            </p>
          </div>
          <!-- <p>
            لطفا نرم افزارهای مورد استفاده خود را به آخرین نسخه حتما بروز رسانی کنید
          </p>
          <p>برای مشاهده آموزش استفاده از نرم افزارها :</p>
          <p>
            <a style="color:red" target="_blank" href="https://wiki.cloakify.site/"> کلیک کنید </a>
          </p>
           -->
          <!-- <p>وضعیت شبکه در حال استیبل قرار دارد.</p>
          <p>به زودی آپدیت های جدیدی روی وبسایت و شبکه قرار میگیرد</p>
          <p>برای آخرین اخبار عضو کانال ما باشید:</p>
          <p>
            <a target="_blank" href="https://t.me/cloakify">@cloakify</a>
          </p> -->
        </div>
        <!-- Modal footer -->
        <div class="modal-footer" style="margin: 0; width: 100% !important">
          <button
            type="button"
            class="btn btn-success h-60 w-100"
            data-bs-dismiss="modal"
            @click="closeModal"
          >
            باشه
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style scoped>
.modal-content {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
</style>
