import { createSSRApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/sass/main.scss";
import Slideout from "@hyjiacan/vue-slideout";
import "@hyjiacan/vue-slideout/dist/slideout.css";
import axios from "axios";
import moshaToast from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.baseURL = "https://admin.cloakify.site/api";
axios.defaults.baseURL = "/api/api";
// axios.defaults.baseURL = "http://localhost:8000/api";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      store.commit("LOGOUT");
      window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);

let app = createSSRApp(App);

app.directive("click-outside", {
  beforeMount: function (el, binding, vnode) {
    if (typeof binding.value !== "function") {
      console.error(
        "click-outside directive's value should be a function reference"
      );
      return;
    }
    el._clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    document.body.addEventListener("click", el._clickOutsideEvent);
  },
  unmounted: function (el) {
    document.body.removeEventListener("click", el._clickOutsideEvent);
  },
});

app.use(store).use(router).use(Slideout).use(moshaToast).mount("#app");
