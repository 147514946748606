<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="48px"
    height="54px"
    viewBox="0 0 48 54"
  >
    <g fill="#000000" fill-rule="nonzero">
      <path
        d="M43.2692677,28.3954708 L30.9233723,26.6208 L25.3677785,15.4323692 C24.9963323,14.6652554 24.0732554,14.3446154 23.3061415,14.7162092 C22.9936246,14.8675938 22.7412185,15.1198523 22.5899815,15.4323692 L17.0343877,26.6208 L4.68849231,28.3954708 C4.10377846,28.48896 3.62407385,28.9085538 3.45393231,29.4756923 C3.30387692,30.0221538 3.45009231,30.6073108 3.83970462,31.0189292 L12.7904492,39.73824 L10.7071015,52.0068923 C10.6133169,52.5957415 10.8503631,53.1884308 11.3244554,53.5501292 C11.7913108,53.9053292 12.4192985,53.9651446 12.9447877,53.7044677 L23.97888,47.8401969 L35.0129723,53.7044677 L35.7074215,53.8588062 C36.0453415,53.8874585 36.38016,53.7758031 36.6333046,53.5501292 C37.1072492,53.1884308 37.3444431,52.5957415 37.2506585,52.0068923 L35.1673108,39.73824 L44.1180554,31.0189292 C44.5076677,30.6073108 44.6540308,30.0221538 44.5038277,29.4756923 C44.3336862,28.9087015 43.8539815,28.48896 43.2692677,28.3954708 Z M32.3895138,38.1179077 C32.0737477,38.4768 31.9325538,38.9569477 32.0037415,39.4297108 L33.7013169,49.4606769 L24.6733292,44.7538708 C24.2337969,44.5453292 23.7239631,44.5453292 23.2844308,44.7538708 L14.2564431,49.4606769 L15.9540185,39.4297108 C16.0252062,38.9569477 15.8840123,38.4769477 15.5682462,38.1179077 L8.23783385,31.0190769 L18.3460431,29.5529354 C18.84288,29.4656492 19.2708923,29.1518031 19.5035077,28.7041477 L23.97888,19.5990646 L28.4542523,28.7041477 C28.6868677,29.1518031 29.1147323,29.4656492 29.6117169,29.5529354 L39.7199262,31.0190769 L32.3895138,38.1179077 Z"
      ></path>
      <path
        d="M23.97888,10.8026585 C24.8312123,10.8026585 25.5221169,10.1117538 25.5221169,9.25942154 L25.5221169,1.54323692 C25.5221169,0.690904615 24.8310646,0 23.97888,0 C23.1266954,0 22.4356431,0.690904615 22.4356431,1.54323692 L22.4356431,9.25942154 C22.4356431,10.1117538 23.1265477,10.8026585 23.97888,10.8026585 Z"
      ></path>
      <path
        d="M47.2816246,8.1792 C46.5997292,7.66774154 45.6324923,7.80598154 45.1210338,8.48787692 L40.4913231,14.6608246 C39.9800123,15.34272 40.1181046,16.3099569 40.8,16.8214154 C41.0643692,17.0270031 41.3910646,17.136 41.7258831,17.1300923 C42.2088369,17.11872 42.6615138,16.8923077 42.9604431,16.5127385 L47.5901538,10.3397908 C48.10176,9.65789538 47.96352,8.69051077 47.2816246,8.1792 Z"
      ></path>
      <path
        d="M6.23158154,17.1299446 C6.5664,17.1358523 6.89324308,17.0268554 7.15746462,16.8212677 C7.83936,16.3098092 7.97745231,15.3425723 7.46614154,14.6606769 L2.83657846,8.48772923 C2.32512,7.80583385 1.35788308,7.66774154 0.675987692,8.17905231 C-0.00590769231,8.69036308 -0.144,9.65774769 0.367310769,10.3396431 L4.99702154,16.5125908 C5.29595077,16.89216 5.74862769,17.1184246 6.23158154,17.1299446 Z"
      ></path>
    </g>
  </svg>
</template>
