<template>
  <button
    id="clickModalSupport"
    ref="clickModalSupport"
    style="display: none"
    data-bs-toggle="modal"
    data-bs-target="#supportModal"
  ></button>
  <button
    id="clickModalBuyTut"
    ref="clickModalBuyTut"
    style="display: none"
    data-bs-toggle="modal"
    data-bs-target="#buyTutModal"
  ></button>
  <supportModal></supportModal>
  <buyTutModal></buyTutModal>
  <nav
    class="container-fluid nav-in-desktop"
    style="
      box-shadow: 0 10px 26px rgba(0, 0, 0, 0.05);
      border-bottom: 1px solid #e0e0e4 !important;
    "
    dir="rtl"
  >
    <div class="container">
      <div class="d-flex justify-content-between align-items-center">
        <div class="show-in-desktop">
          <router-link class="routelink" to="/"
            ><img style="height: 60px" src="@/assets/images/logo.png" alt=""
          /></router-link>
          <a class="parentlink"
            ><router-link class="routelink" to="/">صفحه اصلی</router-link></a
          >
          <a class="parentlink"
            ><router-link class="routelink" to="/store"
              >خرید آی پی ثابت</router-link
            ></a
          >
          <span class="parentlink dropdown">
            <a
              class="routelink nav-link"
              href="#"
              id="navbarDropdownMenuLink"
              @click="openModalSupport"
            >
              پشتیبانی
            </a>
          </span>
          <!-- <span class="parentlink dropdown">
            <a
              class="routelink nav-link"
              href="#"
              id="navbarDropdownMenuLink"
              @click="openModalBuyTut"
            >
              آموزش خرید
            </a>
          </span> -->
          <span class="parentlink dropdown">
            <a
              class="routelink-bef nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              @click="openDropDown"
              v-click-outside="closeDropDown"
            >
              آموزش اتصال
            </a>
            <div
              ref="dropDown"
              class="dropdown-menu"
              aria-labelledby="navbarDropdownMenuLink"
              style="left: 0 !important"
            >
              <a
                target="_blank"
                class="dropdown-item"
                href="https://wiki.cloakify.site"
                >آموزش اندروید</a
              >
              <a
                target="_blank"
                class="dropdown-item"
                href="https://wiki.cloakify.site"
                >آموزش آیفون</a
              >
              <a
                target="_blank"
                class="dropdown-item"
                href="https://wiki.cloakify.site"
                >آموزش دسکتاپ</a
              >
            </div>
          </span>
        </div>
        <div class="show-in-tablet">
          <router-link class="routelink" to="/"
            ><img height="60" src="@/assets/images/logo.png" alt=""
          /></router-link>
          <a><i @click="openMenu" class="fa fa-bars"></i></a>
          <silkroad-side-menu
            @openModalSupport="openModalSupport"
            @openModalBuyTut="openModalBuyTut"
            ref="sideMenuRef"
          ></silkroad-side-menu>
        </div>
        <div>
          <span
            @click="$router.push({ name: 'cart' })"
            class="preview-shopping-cart"
            ><i class="fas fa-shopping-cart"></i>
            <span
              v-if="basketCount"
              class="cart-basket d-flex align-items-center justify-content-center"
            >
              {{ basketCount }}
            </span>
          </span>
          <button
            @click="$router.push({ name: 'main-dashboard' })"
            class="btn mx-1 btn-info silk-btn"
          >
            حساب کاربری
          </button>
          <!-- <button
            @click="$router.push({ name: 'wallet' })"
            class="btn mx-1 btn-info silk-btn"
          >
            کیف پول
          </button> -->
          <button @click="changeTheme" class="btn btn-info mx-1 silk-btn">
            <i :class="theme == 'dark' ? 'fa-sun' : 'fa-moon'" class="fas"></i>
          </button>
        </div>
      </div>
    </div>
  </nav>
  <nav
    class="nav-in-mobile pt-3 pb-3 d-flex justify-content-around align-items-center"
    dir="rtl"
  >
    <div>
      <router-link class="routelink" to="/"
        ><img height="60" src="@/assets/images/logo.png" alt=""
      /></router-link>
      <a><i @click="openMenu" class="fa fa-bars"></i></a>
    </div>
    <silkroad-side-menu
      @openModalSupport="openModalSupport"
      @openModalBuyTut="openModalBuyTut"
      ref="sideMenuRef"
    ></silkroad-side-menu>
    <div>
      <span
        @click="$router.push({ name: 'cart' })"
        class="preview-shopping-cart"
        ><i class="fas fa-shopping-cart"></i>
        <span
          v-if="basketCount"
          class="cart-basket d-flex align-items-center justify-content-center"
        >
          {{ basketCount }}
        </span>
      </span>
      <button
        @click="$router.push({ name: 'main-dashboard' })"
        class="btn btn-info silk-btn"
      >
        <i class="fas fa-user"></i>
      </button>
      <!-- <button
        @click="$router.push({ name: 'wallet' })"
        class="btn btn-info mx-1 silk-btn"
      >
        <i class="fas fa-wallet"></i>
      </button> -->
      <button @click="changeTheme" class="btn btn-info mx-1 silk-btn">
        <i :class="theme == 'dark' ? 'fa-sun' : 'fa-moon'" class="fas"></i>
      </button>
    </div>
  </nav>
</template>

<script>
import SilkroadSideMenu from "@/components/SilkroadSideMenu.vue";
import supportModal from "@/components/supportModal.vue";
import buyTutModal from "@/components/buyTutModal.vue";

export default {
  components: {
    SilkroadSideMenu,
    supportModal,
    buyTutModal,
  },
  computed: {
    basketCount() {
      return this.$store.state.cart.count;
    },
    theme() {
      return localStorage.getItem("cloakify_theme") ?? "light";
    },
  },
  methods: {
    changeTheme() {
      let theme = localStorage.getItem("cloakify_theme");
      if (!theme) {
        localStorage.setItem("cloakify_theme", "dark");
      } else {
        if (theme == "light") {
          localStorage.setItem("cloakify_theme", "dark");
        } else {
          localStorage.setItem("cloakify_theme", "light");
        }
      }
      theme = localStorage.getItem("cloakify_theme");

      if (theme == "light") {
        document.querySelector("body").setAttribute("data-bs-theme", "light");
      } else {
        document.querySelector("body").setAttribute("data-bs-theme", "dark");
      }
    },
    openTutorials() {
      window.open("https://wiki.cloakify.site/", "_blank");
    },
    openMenu() {
      this.$refs.sideMenuRef.openMenu();
    },
    closeDropDown() {
      this.$refs.dropDown.style.display = "none";
    },
    openDropDown() {
      if (this.$refs.dropDown.style.display == "block") {
        this.$refs.dropDown.style.display = "none";
      } else {
        this.$refs.dropDown.style.display = "block";
      }
    },
    openModalSupport() {
      this.$refs.clickModalSupport.click();
    },
    openModalBuyTut() {
      this.$refs.clickModalBuyTut.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.fa-bars,
.fa-window-close {
  font-size: 20px;
  vertical-align: middle;
  cursor: pointer;
}
.routelink {
  margin-left: 20px;
}

.preview-shopping-cart {
  position: relative;
  padding: 10px;
}
.preview-shopping-cart i {
  font-size: 30px;
  vertical-align: middle;
  cursor: pointer;
}
.silk-btn {
  padding: 10px 15px;
  color: #fff;
}
.silk-btn:hover {
  padding: 10px 15px;
  color: #fff;
}
.nav-in-desktop {
  padding: 35px 0;
}
@media screen and (max-width: 768px) {
  .nav-in-desktop {
    display: none !important;
  }
  .nav-in-mobile {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .nav-in-desktop {
    display: block;
  }
  .nav-in-mobile {
    display: none !important;
  }
}

@media screen and (max-width: 1000px) {
  .show-in-tablet {
    display: block;
  }
  .show-in-desktop {
    display: none;
  }
}
@media screen and (min-width: 1000px) {
  .show-in-tablet {
    display: none;
  }
  .show-in-desktop {
    display: block;
  }
}

.nav-link {
  display: inline;
}

.drop-down-menu {
  left: -125px;
  top: 20px !important;
}

.cart-basket {
  font-size: 0.6rem;
  position: absolute;
  top: -2px;
  right: -2px;
  width: 22px;
  height: 22px;
  color: #fff;
  background-color: #418deb;
  border-radius: 50%;
}
</style>
