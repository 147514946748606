<template>
  <main
    class="main-content mt-5 mb-5 d-flex align-items-center"
    style="height: 500px"
    dir="rtl"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>ثبت نام</h5>
            </div>
            <div class="card-body">
              <form role="form" @submit.prevent>
                <div class="row mb-2">
                  <div class="col-12 mt-2 col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="نام"
                      v-model="first_name"
                    />
                  </div>
                  <div class="col-12 mt-2 col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="نام خانوادگی"
                      v-model="last_name"
                    />
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-12">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="ایمیل"
                      v-model="email"
                    />
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-12">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="موبایل"
                      v-model="username"
                    />
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-12">
                    <input
                      type="password"
                      class="form-control"
                      placeholder="رمز"
                      v-model="password"
                    />
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-12">
                    <input
                      type="password"
                      class="form-control"
                      placeholder="تکرار رمز"
                      v-model="repeat_password"
                    />
                  </div>
                </div>
                <div>
                  <label
                    class="form-check-label d-flex justify-content-between"
                    for="flexCheckDefault"
                  >
                    <span>
                      <a class="text-dark font-weight-bolder"> پذیرش شرایط </a>
                      و سیاست حریم خصوصی
                    </span>
                    <input
                      type="checkbox"
                      name="accept_account"
                      required=""
                      checked
                    />
                  </label>
                </div>
                <div class="text-center">
                  <button
                    class="my-4 mb-2 w-100 btn btn-primary"
                    @click="register"
                  >
                    ثبت نام
                  </button>
                </div>
                <p class="text-sm mt-3 mb-0">
                  حساب کاربری دارید ؟
                  <router-link to="/login" class="text-dark font-weight-bolder"
                    >ورود</router-link
                  >
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import notification from "@/mixins/notification.js";
import errorReturner from "@/mixins/errorReturner.js";

export default {
  mixins: [notification, errorReturner],
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      username: "",
      password: "",
      repeat_password: "",
    };
  },
  methods: {
    register() {
      if (this.password !== this.repeat_password) {
        this.errorNotification(
          this.errorMaker({
            response: {
              data: {
                message: "رمز عبور و تکرار رمز باید یکسان باشند",
              },
            },
          })
        );
        return;
      }
      this.$store
        .dispatch("register", {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          mobile: this.username,
          password: this.password,
        })
        .then((response) => {
          this.$store.commit("SET_USER_DATA_AND_TOKEN", response);
          this.successNotification({
            message: "باموفقیت ثبت نام شد",
          });
          this.$router.push({ name: "main-dashboard" });
        })
        .catch((error) => {
          this.errorNotif = this.errorMaker(error);
          this.errorNotification(this.errorNotif);
        });
    },
  },
};
</script>

<style></style>
