<template>
  <div class="container-fluid mt-10" dir="rtl">
    <div class="container bg-of-telegram">
      <div class="row g-5">
        <div class="col-1 d-flex justify-content-around flex-column">
          <span></span>
          <img src="@/assets/images/goto-tel.png" alt="" />
        </div>
        <div class="col-10 d-flex justify-content-around flex-column">
          <h2 class="mt-3">کانال تلگرام  cloakify</h2>
          <p class="mt-3">
            برای دریافت آخرین خبر های مربوطه توی کانال ما عضو بشید
          </p>
          <button class="btn btn-primary btn buy-btn p-3">
            عضویت در تلگرام
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.buy-btn {
  width: 200px !important;
}
.mt-10 {
  padding-top: 100px;
}
.bg-of-telegram {
  background-color: #f2f2f2;
  background-image: url("../../assets/images/tel-bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

body[data-bs-theme="dark"] {
  .bg-of-telegram{
    background-color: #393939 !important;
  }
}

@media screen and (max-width: 600px) {
  .bg-of-telegram {
    background-position: center left;
  }
}
</style>
