import axios from "axios";

export default {
  data() {
    return {};
  },
  methods: {
    getPaymentToken(orderId, gateway = "sizpay") {
      return axios.get(`/payment-token/${orderId}`).then((response) => {
        return response.data.data.token;
      });
    },
    redirectToGateway(orderId, gateway) {
      const form = document.createElement("form");
      form.setAttribute("method", "POST");
      form.setAttribute("action", "https://admin.cloakify.site/api/pay");
      form.setAttribute("target", "_self");
      const hiddenField = document.createElement("input");
      hiddenField.setAttribute("name", "order_id");
      hiddenField.setAttribute("value", orderId);

      const hiddenField1 = document.createElement("input");
      hiddenField1.setAttribute("name", "payment_method");
      hiddenField1.setAttribute("value", gateway);

      form.appendChild(hiddenField);
      form.appendChild(hiddenField1);
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    },
  },
};
