<template>
  <div class="d-flex justify-content-center">
    <div
      class="sleek-form text-right d-flex flex-column justify-content-center align-items-center col-12 col-sm-6 p-0"
    >
      <div class="px-3 px-sm-5 pt-5">
        <h3 class="text-center text-gold">شارژ کیف پول</h3>
        <div
          dir="rtl"
          role="alert"
          aria-live="polite"
          aria-atomic="true"
          class="alert rtl p-2 alert-info"
        >
          شارژ فعلی شما : {{ userBalanceToToman }} تومان
        </div>
        <div
          dir="rtl"
          role="alert"
          aria-live="polite"
          aria-atomic="true"
          class="alert rtl p-2 alert-info"
        >
          لطفا برای تهیه ووچر پرفکت مانی روی یکی از سایت های زیر کلیک کنید
        </div>
        <div
          dir="rtl"
          role="alert"
          aria-live="polite"
          aria-atomic="true"
          class="alert rtl p-2 alert-info"
        >
          <p><a href="https://arzanpay724.com/" target="_blank">ارزان پی</a></p>
          <p><a href="http://cafearz.com/" target="_blank">کافه ارز</a></p>
        </div>
        <fieldset class="form-group mt-3" id="__BVID__288">
          <!---->
          <div>
            <input
              dir="rtl"
              type="text"
              placeholder="شماره ووچر"
              class="form-control"
              v-model="EVnumber"
              id="__BVID__289"
            />
          </div>
        </fieldset>

        <fieldset class="form-group mt-3" id="__BVID__288">
          <!---->
          <div>
            <input
              dir="rtl"
              type="text"
              placeholder="کد فعالسازی"
              class="form-control"
              v-model="EVactivationCode"
              id="__BVID__289"
            />
          </div>
        </fieldset>
        <!---->
        <button
          @click="chargeWallet"
          class="p-2 mt-3 btn btn-info rounded silk-btn shadow-4 w-100"
        >
          شارژ حساب
        </button>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import notification from "@/mixins/notification.js";
import errorReturner from "@/mixins/errorReturner.js";
import axios from "axios";

export default {
  mixins: [errorReturner, notification],
  data() {
    return {
      EVactivationCode: "",
      EVnumber: "",
      userBalance: 0,
      dollarPrice: 0,
    };
  },
  components: {},
  computed: {
    userBalanceToToman() {
      return this.userBalance * this.dollarPrice;
    },
  },
  methods: {
    chargeWallet() {
      console.log("clicked");
      if (!this.EVnumber || !this.EVactivationCode) {
        this.errorNotification(
          this.errorMaker({
            response: {
              data: {
                message: "لطفا شماره و کدفعالسازی ووچر را وارد کنید",
              },
            },
          })
        );
        return;
      }

      axios
        .post("charge-wallet", {
          EVnumber: this.EVnumber,
          EVactivationCode: this.EVactivationCode,
        })
        .then((res) => {
          this.userBalance = res.data.data.wallet.balance;
          this.dollarPrice = res.data.data.dollar;
          this.successNotification({
            message: "کیف پول شما با موفقیت شارژ شد",
          });

          this.EVactivationCode = "";
          this.EVnumber = "";
        })
        .catch((error) => {
          this.errorNotif = this.errorMaker(error);
          this.errorNotification(this.errorNotif);
        });
    },
  },
  mounted() {
    axios
      .get("get-user-wallet")
      .then((res) => {
        this.userBalance = res.data.data.wallet.balance;
        this.dollarPrice = res.data.data.dollar;
      })
      .catch((error) => {
        this.errorNotif = this.errorMaker(error);
        this.errorNotification(this.errorNotif);
      });
  },
};
</script>

<style scoped>
.silk-btn {
  padding: 10px 15px;
  color: #fff;
}
.silk-btn:hover {
  padding: 10px 15px;
  color: #fff;
}
</style>
