<template>
  <div
    id="modalCode"
    class="modal fade show"
    tabindex="-1"
    aria-labelledby="textToCopy"
    aria-modal="true"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div v-if="!code" class="modal-content">
        <div class="loader"></div>
        <p>در حال دریافت کد</p>
      </div>
      <div v-else class="modal-content" style="padding: 20px !important">
        <div class="modal-body text-center">
          <h5 class="modal-title">
            <input
              class="form-control"
              id="textToCopy"
              type="text"
              :value="code"
            />
          </h5>
        </div>
        <div
          class="modal-footer"
          style="
            width: 100%;
            justify-content: center;
            padding: 10px 0px 5px 0px !important;
            margin: 0 !important;
          "
        >
          <button type="button" class="btn btn-success w-50" @click="copyCode">
            کپی کردن
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import notification from "@/mixins/notification.js";
import errorReturner from "@/mixins/errorReturner.js";

export default {
  mixins: [notification, errorReturner],
  data() {
    return {
      code: "",
    };
  },
  methods: {
    setCode(code) {
      this.code = code;
    },
    copyCode() {
      var copyText = document.getElementById("textToCopy");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
      this.successNotification({
        message: "با موفقیت کپی شد",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-custom-modal {
  width: 45%;
}
.btn-danger {
  @extend .btn-custom-modal;
}
</style>
