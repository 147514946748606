<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3>تحویل سفارش به صورت آنی</h3>
        </div>
        <div class="card-body">
          <p>
            بعد از خرید محصول میتوانید از بخش کد های خریداری شده کد خود را
            دریافت کنید.
          </p>
          <p class="mt-4">
            لطفا برای دریافت کد حساب کاربری خود به پشتیبانی پیغام ندهید
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
li {
  list-style: none;
  cursor: pointer;
}
.active {
  color: #0071dc;
}
</style>
