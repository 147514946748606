<template>
  <div class="container-fluid mt-5" dir="rtl">
    <div class="container">
      <div class="row g-5">
        <div class="col-12 col-md-6 col-lg-3">
          <div class="row info-card text-center">
            <div class="col-12">
              <img src="@/assets/images/regions.png" alt="" />
            </div>
            <div class="col-12 mt-3">
              <h3>ریجن های مختلف</h3>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="row info-card text-center">
            <div class="col-12">
              <img src="@/assets/images/support.png" alt="" />
            </div>
            <div class="col-12 mt-3">
              <h3>پشتیبانی خوب و قوی</h3>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="row info-card text-center">
            <div class="col-12">
              <img src="@/assets/images/rocket.png" alt="" />
            </div>
            <div class="col-12 mt-3">
              <h3>سرعت عالی برای وبگردی</h3>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="row info-card text-center">
            <div class="col-12">
              <img src="@/assets/images/security.png" alt="" />
            </div>
            <div class="col-12 mt-3">
              <h3>امنیت مناسب</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.info-card {
  background: #fff !important;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

body[data-bs-theme="dark"] {
  .info-card {
    background: #393939 !important;
  }
}

.info-card:hover {
  box-shadow: 1px 1px 10px 2px #e0dfdf;
}
</style>
