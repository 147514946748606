<template>
  <div class="cotainer-fluid">
    <div
      v-for="(notification, index) in notifications"
      :key="index"
      class="alert mt-3"
      :class="`alert-${notification.color}`"
    >
      <p>
        {{ notification.text }}
      </p>
    </div>
  </div>
</template>

<script>
import notification from "@/mixins/notification.js";
import errorReturner from "@/mixins/errorReturner.js";
import axios from "axios";

export default {
  mixins: [notification, errorReturner],
  data() {
    return {
      notifications: [],
    };
  },
  mounted() {
    if (this.$route.query._back) {
      let queryParam = JSON.parse(this.$route.query._back);
      if (queryParam.query.icon == "error") {
        this.errorNotif = queryParam.query.message;
        this.errorNotification(this.errorNotif);
      } else if (queryParam.query.icon == "success") {
        this.successNotification({
          message: queryParam.query.message,
        });
      }
    }

    axios.get("notifications").then((response) => {
      this.notifications = response.data.data;
    });
  },
};
</script>
