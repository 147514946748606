<template>
  <div class="container-fluid px-5 py-4" dir="rtl">
    <span v-if="parentOfparent" @click="$router.push({ name: parentOfParentPath })" style="color: #ababab"
      >{{ parentOfparent }} / 
    </span>
    <span @click="$router.push({ name: parentPath })" style="color: #ababab"
      >{{ parnetRoute }}
    </span>
    /
    <span> {{ currentRoute }}</span>
    <div
      class="container text-center h-150 d-flex justify-content-center align-items-center"
    >
      <h1>{{ thisRouteName }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    thisRouteName: {
      type: String,
      require: true,
    },
  },
  computed: {
    parentPath() {
      return this.$route.meta.parentPath;
    },
    parentOfparent(){
      return this.$route.meta.parentOfparent;
    },
    parentOfParentPath(){
      return this.$route.meta.parentOfParentPath;
    },
    parnetRoute() {
      return this.$route.meta.parnetRoute;
    },
    currentRoute() {
      return this.$route.meta.currentRoute;
    },
  },
};
</script>

<style lang="scss" scoped>
.h-150 {
  height: 150px;
}
</style>
