import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    product: {},
    cart: localStorage.getItem("cart")
      ? JSON.parse(localStorage.getItem("cart"))
      : {
          count: 0,
          products: [],
          ids: [],
        },
    user: {},
    token: "",
    topNotification: [],
  },
  mutations: {
    SET_TOP_NOTIF(state, notif) {
      state.topNotification = notif;
    },
    EMPTY_CART(state) {
      localStorage.removeItem("cart");
      state.cart = {
        count: 0,
        products: [],
        ids: [],
      };
    },
    LOGOUT() {
      localStorage.removeItem("silkroad_user");
      localStorage.removeItem("silkroad_token");
      axios.defaults.headers.common["Authorization"] = `Bearer`;
    },
    SET_USER_DATA_AND_TOKEN(state, payload) {
      state.user = payload.data;
      state.token = payload.data.token;
      localStorage.setItem("silkroad_user", JSON.stringify(payload.data));
      localStorage.setItem("silkroad_token", payload.data.token);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${payload.token}`;
    },
    SET_PRODUCT(state, data) {
      state.product = data;
    },
    MINUS_QTY_FROM_CART(state, product) {
      let foundIndex = state.cart.products.findIndex((el) => {
        return el.id === product.id;
      });
      if (foundIndex != -1) {
        state.cart.products[foundIndex].qty--;
        state.cart.count -= 1;
      }

      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    REMOVE_FROM_CART(state, product) {
      let findIndex = state.cart.products.findIndex((el) => {
        return product.id === el.id;
      });

      let count = state.cart.products[findIndex].qty;

      state.cart.count -= count;

      state.cart.products.splice(findIndex, 1);

      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    ADD_TO_CART_BY_QTY(state, payload) {
      state.cart.count += payload.qty;
      if (state.cart.products.length) {
        if (state.cart.ids.includes(payload.product.id)) {
          state.cart.products.forEach((element) => {
            if (element.id === payload.product.id) {
              element.qty
                ? (element.qty += payload.qty)
                : (element.qty = payload.qty);
            }
          });
        } else {
          state.cart.products.push(payload.product);
          let foundIndex = state.cart.products.findIndex((el) => {
            return el.id === payload.product.id;
          });
          state.cart.products[foundIndex].qty
            ? (state.cart.products[foundIndex].qty += payload.qty)
            : (state.cart.products[foundIndex].qty = payload.qty);
        }
      } else {
        state.cart.products.push(payload.product);
        state.cart.products[0].qty = payload.qty;
      }
      if (!state.cart.ids.includes(payload.product.id)) {
        state.cart.ids.push(payload.product.id);
      }

      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    ADD_TO_CART(state, product) {
      state.cart.count++;
      if (state.cart.products.length) {
        if (state.cart.ids.includes(product.id)) {
          state.cart.products.forEach((element) => {
            if (element.id === product.id) {
              element.qty ? element.qty++ : (element.qty = 1);
            }
          });
        } else {
          state.cart.products.push(product);
          let foundIndex = state.cart.products.findIndex((el) => {
            return el.id === product.id;
          });
          state.cart.products[foundIndex].qty
            ? state.cart.products[foundIndex].qty++
            : (state.cart.products[foundIndex].qty = 1);
        }
      } else {
        state.cart.products.push(product);
        state.cart.products[0].qty = 1;
      }
      if (!state.cart.ids.includes(product.id)) {
        state.cart.ids.push(product.id);
      }

      localStorage.setItem("cart", JSON.stringify(state.cart));
    },
  },
  actions: {
    getAllProducts({ commit }) {
      return axios.get("/products").then(({ data }) => {
        return data;
      });
    },
    getProduct({ commit }, payload) {
      return axios.get("/products/" + payload.id).then(({ data }) => {
        return data;
      });
    },
    register({ commit }, payload) {
      return axios.post("/register", payload).then(({ data }) => {
        return data;
      });
    },
  },
  getters: {},
  modules: {},
});
