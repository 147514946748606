<template>
  <div class="container-fluid" dir="rtl">
    <div class="container">
      <div class="row g-5">
        <div class="col-12 col-lg-6">
          <div class="row game-trade-card bg-trade">
            <div
              class="col-12 col-lg-6 d-flex justify-content-center align-items-center flex-column mt-3"
            >
              <span class="theme-main-text-color"> آیپی ثابت </span>
              <h2 class="mt-3">مخصوص ترید</h2>
              <button class="btn btn-primary mt-3 p-3 w-50">بزودی</button>
            </div>
            <div class="col-12 col-lg-6 d-flex justify-content-center mt-3">
              <img src="@/assets/images/trade.png" alt="" />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="row game-trade-card bg-game">
            <div
              class="col-12 col-lg-6 d-flex justify-content-center align-items-center flex-column mt-3"
            >
              <span class="theme-main-text-color"> آیپی ثابت </span>
              <h2 class="mt-3">مخصوص بازی</h2>
              <button class="btn btn-primary mt-3 p-3 w-50">بزودی</button>
            </div>
            <div class="col-12 col-lg-6 d-flex justify-content-center mt-3">
              <img src="@/assets/images/game.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.game-trade-card {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  padding-bottom: 20px;
}

.bg-game,
.bg-trade {
  background: #eff5f7 !important;
}

body[data-bs-theme="dark"] {
  .bg-game,
  .bg-trade {
    background: #393939 !important;
  }
}
</style>
