<template>
  <main
    class="main-content mt-5 mb-5 d-flex align-items-center"
    style="height: 500px"
    dir="rtl"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>فراموشی رمز عبور</h5>
            </div>
            <div class="card-body">
              <form role="form" @submit.prevent>
                <div class="row mb-2">
                  <div class="col-12">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="ایمیل"
                      v-model="email"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-7 col-sm-8">
                    <input
                      v-model="token"
                      type="text"
                      class="form-control"
                      placeholder="کد تایید ارسال شده"
                    />
                  </div>
                  <div class="col-5 col-sm-4">
                    <button
                      :disabled="canSendEmail"
                      :class="canSendEmail ? 'btn-gray' : ''"
                      @click="sendVerifyEmail"
                      style="color: #fff"
                      class="btn btn-info w-100"
                    >
                      ارسال کد
                    </button>
                  </div>
                </div>
                <div v-show="showChangePasswordParts" class="row mb-2">
                  <div class="col-12">
                    <input
                      type="password"
                      class="form-control"
                      placeholder="رمز"
                      v-model="password"
                    />
                  </div>
                </div>
                <div v-show="showChangePasswordParts" class="row mb-2">
                  <div class="col-12">
                    <input
                      type="password"
                      class="form-control"
                      placeholder="تکرار رمز"
                      v-model="repeat_password"
                    />
                  </div>
                </div>
                <div v-show="showChangePasswordParts" class="text-center">
                  <button
                    :disabled="canResetPassword"
                    :class="canResetPassword ? 'btn-gray' : ''"
                    @click="resetPassword"
                    class="btn btn-primary w-100"
                  >
                    تغییر رمز
                  </button>
                </div>
                <p class="text-sm mt-3 mb-0">
                  <router-link to="/login" class="text-dark font-weight-bolder"
                    >بازگشت به ورود</router-link
                  >
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import notification from "@/mixins/notification.js";
import errorReturner from "@/mixins/errorReturner.js";
import axios from "axios";

export default {
  data() {
    return {
      can_send_email: true,
      can_reset_password: true,
      repeat_password: "",
      password: "",
      token: "",
      email: "",
      can_show_password_part: false,
      loading: false,
    };
  },
  mixins: [notification, errorReturner],
  computed: {
    canSendEmail() {
      return !(this.can_send_email && this.email) || this.loading;
    },
    showChangePasswordParts() {
      return (this.email && this.token) || this.can_show_password_part;
    },
    canResetPassword() {
      return !(
        this.email &&
        this.token &&
        this.password &&
        this.repeat_password
      );
    },
  },
  methods: {
    sendVerifyEmail() {
      this.loading = true;
      axios
        .post("request-verify-email", { email: this.email })
        .then((response) => {
          this.successNotification({
            message: "باموفقیت ارسال شد",
          });
          this.can_send_email = false;
          this.can_show_password_part = true;
          this.loading = false;
        })
        .catch((error) => {
          this.errorNotif = this.errorMaker(error);
          this.errorNotification(this.errorNotif);
          this.loading = false;
        });
    },
    resetPassword() {
      if (this.password !== this.repeat_password) {
        this.errorNotification(
          this.errorMaker({
            response: {
              data: {
                message: "رمز عبور و تکرار رمز باید یکسان باشند",
              },
            },
          })
        );
        return;
      }
      axios
        .post("reset-password", {
          email: this.email,
          password: this.password,
          token: this.token,
        })
        .then((response) => {
          this.$store.commit("SET_USER_DATA_AND_TOKEN", response);
          this.successNotification({
            message: "باموفقیت تغییر یافت",
          });
          this.$router.push({ name: "dashboard" });
        })
        .catch((error) => {
          this.errorNotif = this.errorMaker(error);
          this.errorNotification(this.errorNotif);
        });
    },
  },
};
</script>

<style scoped>
.btn-gray {
  background: gray !important;
  border: none;
}
</style>
