import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Store from "../views/Store.vue";
import Product from "../views/Product.vue";
import Wallet from "../views/Wallet.vue";
import Cart from "../views/Cart.vue";
import Dashboard from "../views/dashboard/Dashboard.vue";
import MainDashboard from "../views/dashboard/MainDashboard.vue";
import Gifts from "../views/dashboard/Gifts.vue";
import Orders from "../views/dashboard/Orders.vue";
import Notes from "../views/dashboard/Notes.vue";
import Order from "../views/dashboard/Order.vue";
import Profile from "../views/dashboard/Profile.vue";
import Login from "../views/auth/Login.vue";
import Register from "../views/auth/Register.vue";
import ResetPassword from "../views/auth/ResetPassword.vue";
import store from "../store";
import axios from "axios";

const routes = [
  {
    path: "/",
    name: "index",
    component: Home,
    props: true,
  },
  {
    path: "/store",
    name: "store",
    component: Store,
    meta: {
      parnetRoute: "خانه",
      parentPath: "index",
      currentRoute: "محصولات",
    },
    props: true,
  },
  {
    path: "/product/:id",
    name: "product",
    component: Product,
    props: true,
    meta: {
      parnetRoute: "محصولات",
      parentPath: "store",
      currentRoute: "آیپی ثابت",
      parentOfparent: "خانه",
      parentOfParentPath: "index",
    },
  },
  {
    path: "/cart",
    name: "cart",
    component: Cart,
    props: true,
    meta: {
      parnetRoute: "محصولات",
      parentPath: "store",
      currentRoute: "سبد خرید",
      parentOfparent: "خانه",
      parentOfParentPath: "index",
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPassword,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "main-dashboard",
        name: "main-dashboard",
        component: MainDashboard,
      },
      {
        path: "/orders",
        name: "orders",
        component: Orders,
      },
      {
        path: "/wallet",
        name: "wallet",
        component: Wallet,
      },
      {
        path: "/order/:id",
        name: "order",
        component: Order,
      },
      {
        path: "/gifts",
        name: "gifts",
        component: Gifts,
      },
      {
        path: "/notes",
        name: "notes",
        component: Notes,
      },
      {
        path: "/profile",
        name: "profile",
        component: Profile,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const user = localStorage.getItem("silkroad_user");
  const silkroad_token = localStorage.getItem("silkroad_token");
  if (user && silkroad_token) {
    const userData = {
      data: JSON.parse(user),
      token: silkroad_token,
    };
    store.commit("SET_USER_DATA_AND_TOKEN", userData);
    axios.defaults.headers.common["Authorization"] = `Bearer ${silkroad_token}`;
  }
  if (to.matched.some((record) => record.meta.requiresAuth) && !user) {
    next("/login");
  } else {
    next();
  }
});

export default router;
