<template>
  <div
    class="container-fluid dashboard-bg px-5 py-5"
    style="box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 26px"
    dir="rtl"
  >
    <div class="container py-5">
      <div class="row">
        <div class="col-12 col-lg-4 mt-3">
          <div class="row d-flex align-items-center">
            <div class="col-4">
              <img src="@/assets/images/logo.png" class="w-100" alt="" />
            </div>
            <div class="col-8">
              <p>{{ fullname }}</p>
              <p>{{ email }}</p>
              <button @click="logout" class="btn btn-secondary">خروج</button>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <div
                @click="pushTo('main-dashboard')"
                class="dashboard-menu mb-3"
              >
                <span>حساب کاربری</span>
                <span><i class="fa fa-home"></i></span>
              </div>

              <div
                v-if="thisUser.role == 'admin'"
                @click="$router.push({ name: 'wallet' })"
                class="dashboard-menu mb-3"
              >
                <span>کیف پول</span>
                <span><i class="fa fa-wallet"></i></span>
              </div>

              <div @click="pushTo('orders')" class="dashboard-menu mb-3">
                <span>کدهای خریداری شده</span>
                <span><i class="fa fa-file"></i></span>
              </div>
              <div @click="pushTo('gifts')" class="dashboard-menu mb-3">
                <span>کدهای تخفیف</span>
                <span><i class="fa fa-gift"></i></span>
              </div>
              <div @click="pushTo('notes')" class="dashboard-menu mb-3">
                <span>یادداشت ها</span>
                <span><i class="fas fa-sticky-note"></i></span>
              </div>
              <div @click="openTutorials" class="dashboard-menu mb-3">
                <span>آموزش اتصال</span>
                <span><i class="fa fa-glass"></i></span>
              </div>
              <div @click="pushTo('profile')" class="dashboard-menu mb-3">
                <span>ویرایش حساب</span>
                <span><i class="fa fa-pencil"></i></span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-8">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import notification from "@/mixins/notification.js";
import errorReturner from "@/mixins/errorReturner.js";

export default {
  mixins: [notification, errorReturner],
  data() {
    return {
      user: "",
    };
  },
  methods: {
    openTutorials() {
      window.open("https://wiki.cloakify.site/", "_blank");
    },
    pushTo(name) {
      this.$router.push({ name });
    },
    logout() {
      axios
        .post("logout")
        .then((response) => {
          this.$store.commit("LOGOUT");
          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          this.errorNotif = this.errorMaker(error);
          this.errorNotification(this.errorNotif);
        });
    },
  },
  computed: {
    fullname() {
      if (this.user) {
        return this.user.first_name
          ? this.user.first_name
          : "" + " " + this.user.last_name
          ? this.user.last_name
          : "";
      } else {
        return "";
      }
    },
    email() {
      if (this.user) {
        return this.user.email;
      } else {
        return "";
      }
    },
    thisUser() {
      return this.user;
    },
  },
  mounted() {
    axios.get("/user").then((response) => {
      this.user = response.data;
    });
  },
};
</script>

<style lang="scss" scoped>
.dashboard-menu {
  color: #777;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  padding: 15px 10px;
  box-shadow: 0 0 16px 0 rgba(114, 114, 114, 0.16);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.dashboard-menu:hover {
  border: 1px solid #cceae9;
}
</style>
