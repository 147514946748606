<template>
  <Heading :thisRouteName="'سبد خرید'"></Heading>
  <div class="container-fluid pt-5 mt-5" dir="rtl">
    <div class="container">
      <div class="row table-responsive">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th class="p-3">نوع</th>
              <th class="p-3">تعداد</th>
              <th class="p-3">قیمت</th>
              <th class="p-3">جمع کل</th>
              <th class="p-3">عملیات</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product, index) in cart.products" :key="index">
              <td class="p-3">{{ product.title }}</td>
              <td class="p-3 d-flex justify-content-around align-items-center">
                <span
                  ><button
                    @click="addQty(product)"
                    class="btn btn-success text-white"
                  >
                    +
                  </button></span
                ><span>{{ product.qty }}</span
                ><span
                  ><button
                    @click="minQty(product)"
                    class="btn btn-danger text-white"
                  >
                    -
                  </button></span
                >
              </td>
              <td class="p-3">{{ product.price }}</td>
              <td class="p-3">{{ product.price * product.qty }}</td>
              <td class="p-3">
                <span @click="deleteProduct(product)"
                  ><i
                    style="font-size: 20px"
                    class="text-danger fa fa-trash"
                  ></i
                ></span>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="d-flex flex-row justify-content-between align-items-start p-0"
        >
          <div>
            <button
              @click="$router.push({ name: 'store' })"
              class="text-white btn btn-warning"
            >
              بازگشت به فروشگاه
            </button>
          </div>
          <div>
            <p style="color: #93750a !important">
              جمع کل :
              {{ totalPrice.toLocaleString() }}
              تومان
            </p>
            <div class="text-start">
              <button
                v-if="cart.products.length"
                @click="setOrder"
                class="btn btn-success h-30 text-white"
              >
                ثبت سفارش
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "@/components/Heading.vue";
import notification from "@/mixins/notification.js";
import errorReturner from "@/mixins/errorReturner.js";
import axios from "axios";

export default {
  components: {
    Heading,
  },
  mixins: [errorReturner, notification],
  methods: {
    setOrder() {
      if (!this.isLogin) {
        this.$router.push({ name: "login" });
        return;
      } else {
        axios
          .post("orders", this.cart)
          .then((response) => {
            this.$store.commit("EMPTY_CART");
            this.$router.push({
              name: "order",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.errorNotif = this.errorMaker(error);
            this.errorNotification(this.errorNotif);
          });
      }
      return;
    },
    deleteProduct(product) {
      if (confirm("آیا مطمئن هستید ؟") == true) {
        this.$store.commit("REMOVE_FROM_CART", product);
      }
    },
    minQty(product) {
      let cart = this.$store.state.cart;

      let foundProduct = cart.products.find((el) => {
        return el.id === product.id;
      });

      if (foundProduct) {
        if (foundProduct.qty == 1) {
          this.$store.commit("REMOVE_FROM_CART", product);
        } else if (foundProduct.qty > 1) {
          this.$store.commit("MINUS_QTY_FROM_CART", product);
        }
      }
    },
    addQty(product) {
      if (this.$store.state.cart.count >= 5) {
        this.errorNotification(
          this.errorMaker({
            response: {
              data: {
                message: "ظرفیت سبد خرید شما 5 محصول است",
              },
            },
          })
        );
        return;
      }

      let cart = this.$store.state.cart;

      let foundProduct = cart.products.find((el) => {
        return el.id === product.id;
      });

      if (!product.in_stock) {
        this.errorNotification(
          this.errorMaker({
            response: {
              data: {
                message: "بیش از این مقدار در انبار موجود نیست",
              },
            },
          })
        );
        return;
      }

      if (foundProduct) {
        if (foundProduct.qty >= product.in_stock) {
          this.errorNotification(
            this.errorMaker({
              response: {
                data: {
                  message: "بیش از این مقدار در انبار موجود نیست",
                },
              },
            })
          );
          return;
        }
      }

      this.$store.commit("ADD_TO_CART", product);
    },
  },
  computed: {
    isLogin() {
      return (
        localStorage.getItem("silkroad_user") &&
        localStorage.getItem("silkroad_token")
      );
    },
    cart() {
      return this.$store.state.cart;
    },
    totalPrice() {
      let total = 0;

      this.cart.products.forEach((element) => {
        total += element.qty * element.price;
      });

      return total;
    },
  },
};
</script>

<style scoped>
.h-30 {
  height: 40px;
}
</style>
