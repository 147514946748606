<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h3>عودت وجه</h3>
        </div>
        <div class="card-body">
          <p>
            از طریق ربات تلگرام میتونید سرویس تست ۲۴ ساعته دریافت کنید، در صورتی
            که رضایت داشتید خرید کنید.
          </p>
          ‌
          <p>
            پس از خرید صرفا پشتیبانی انجام خواهد شد و امکان عودت وجه وجود
            ندارد‌.
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3>قیمت دلار</h3>
        </div>
        <div class="card-body">
          <p>قیمت دلار محاسبه شده در سایت قیمت معاملات تتری بازار می باشد</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filter: "popularity",
    };
  },
  methods: {
    setSort(filter) {
      this.filter = filter;
    },
    sortBy(filter) {
      this.filter = filter;
      this.$emit("sortBy", filter);
    },
  },
};
</script>

<style lang="scss">
li {
  list-style: none;
  cursor: pointer;
}
.active {
  color: #0071dc;
}
</style>
