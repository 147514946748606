export default {
  data() {
    return {
      errorNotif: "",
      successNotif: "",
      infoNotif: "",
    };
  },
  methods: {
    errorMaker(error) {
      let errorToReturn = "";
      if (error.response.data.errors) {
        const errors = error.response.data.errors;
        let firstError = Object.keys(errors)[0];
        errorToReturn = errors[firstError][0];
      } else {
        errorToReturn = error.response.data.message;
      }

      return errorToReturn;
    },
  },
};
