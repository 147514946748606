<template>
  <div style="max-height: 650px; overflow-y: auto" class="container-fluid">
    <div class="row table-responsive">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th class="p-3">تاریخ</th>
            <th class="p-3">متن یادداشت</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(note, index) in notes" :key="index">
            <td class="p-3">{{ persianDate(note.created_at) }}</td>
            <td class="p-3">{{ note.description }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import translateDigitMxin from "@/mixins/translateDigitMxin.js";
import mobileViewMixin from "@/mixins/mobileViewMixin";
import notification from "@/mixins/notification.js";
import errorReturner from "@/mixins/errorReturner.js";

export default {
  mixins: [translateDigitMxin, mobileViewMixin, notification, errorReturner],
  data() {
    return {
      notes: [],
    };
  },
  methods: {
    copyCode(voucher) {
      navigator.clipboard.writeText(voucher.code);
      this.successNotification({
        message: "با موفقیت کپی شد",
      });
    },
  },
  mounted() {
    axios.get("/user-notes").then((response) => {
      this.notes = response.data.data;
    });
  },
};
</script>

<style></style>
