<template>
  <div class="container-fluid" dir="rtl">
    <div class="container">
      <div class="row">
        <div
          class="img-top relative col-xs-12 col-s-12 col-md-8 col-lg-7 col-xl-7 col-xxl-7"
          :class="{
            'mt-100': needMargingTop,
            'text-center': { in1430To1610, in1300To1400 },
            'no-mt': in1300To1400NotHome,
          }"
        >
          <img
            :src="imagePath"
            :class="{
              'img-60': in1430To1610,
              'img-80': in1430To1610NotHome,
              'img-80': in1300To1400NotHome,
              'img-50': in1300To1400,
            }"
            class="img-fluid"
            alt=""
          />
          <div class="box-of-info">
            <div class="relative">
              <div class="parent-of-star">
                <star-svg style="width: 25px"></star-svg>
              </div>
              <!--  -->
              <div class="d-flex justify-content-start px-4 mt-3">
                <span v-for="(i, index) in 5" :key="index" class="orange-star"
                  ><i class="fa fa-star"></i
                ></span>
              </div>
              <div class="d-flex justify-content-start px-4 mt-1">
                <span>یک تجربه پر سرعت</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-s-12 col-md-4 col-lg-5 col-xl-5 col-xxl-5">
          <figure class="relative">
            <div class="overlay">
              <div class="header-title">
                <h1 class="theme-main-text-color">{{ headerH1 }}</h1>
                <div class="h-10009045" v-if="headerH2">
                  <h1 class="">
                    <span style="color: #21365f; font-size: 2.5rem">1000</span
                    ><span style="color: #f96434; font-size: 2.5rem">9045</span>
                  </h1>
                </div>
                <h2 class="mt-5">
                  <span>فروش آیپی </span>
                  <span class="relative after-ip">ثابت</span>
                </h2>
                <h3 class="mt-4">
                  <span> برای خرید کلیک کنید </span>
                </h3>
                <button @click="$router.push({name:'store'})" class="mt-4 btn btn-primary buy-btn p-4 w-100">
                  خرید آیپی ثابت
                </button>
              </div>
            </div>
          </figure>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
import starSvg from "@/components/starSvg.vue";
export default {
  name: "TheHeader",
  components: {
    starSvg,
  },
  data() {
    return {};
  },
  props: {
    headerImg: {
      type: String,
      required: true,
    },
    headerH1: {
      type: String,
      required: false,
    },
    headerH2: {
      type: String,
      required: false,
    },
    headerH6: {
      type: String,
      required: false,
    },
  },
  computed: {
    imagePath() {
      return this.headerImg;
    },
    needMargingTop() {
      if (this.$route.name === "index") {
        return false;
      }
      return true;
    },
    in1430To1610() {
      if (
        window.innerWidth <= 1610 &&
        window.innerWidth >= 1430 &&
        this.$route.name === "home"
      ) {
        return true;
      } else {
        return false;
      }
    },
    in1430To1610NotHome() {
      if (
        window.innerWidth <= 1610 &&
        window.innerWidth >= 1430 &&
        this.$route.name !== "home"
      ) {
        return true;
      } else {
        return false;
      }
    },
    in1300To1400() {
      if (
        window.innerWidth <= 1390 &&
        window.innerWidth >= 1300 &&
        this.$route.name === "home"
      ) {
        return true;
      } else {
        return false;
      }
    },
    in1300To1400NotHome() {
      if (window.innerWidth <= 1390 && window.innerWidth >= 1300) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    goToPanel() {},
  },
  mounted() {
    const vm = this;
  },
};
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
}
.overlay {
  top: 140px;
  right: 300px;
}
h1 {
  font-weight: 100 !important;
}
.h-10009045 {
  text-align: center;
}
@media screen and (min-width: 678px) {
  .h-10009045 {
    text-align: center !important;
    margin-left: 45px;
    margin-top: 40px;
  }
}
.container-fluid {
  background-image: url("../assets/images/new-bg-1.png");
  background-repeat: no-repeat;
  background-size: 24%;
  background-position: -11%;
}

@media screen and (max-width: 678px) {
  .container-fluid {
    background-image: url("../assets/images/new-bg-1.png");
    background-repeat: no-repeat;
    background-size: 135%;
    background-position-x: -132px;
    background-position-y: 214%;
  }
}

.img-60 {
  max-width: 60% !important;
}

@media screen and (min-width: 1200px) and (max-width: 1250px) {
  .img-60 {
    max-width: 68% !important;
  }
}

@media screen and (min-width: 1350px) and (max-width: 1400px) {
  .img-60 {
    max-width: 77% !important;
  }
}

@media screen and (min-width: 1401px) {
  .img-60 {
    max-width: 60% !important;
  }
}

@media screen and (min-width: 1700px) {
  .img-60 {
    max-width: 77% !important;
  }
}

.img-80 {
  max-width: 80% !important;
}

.img-50 {
  max-width: 50% !important;
}

.reg-btn {
  background: #f96434;
  border: none;
  padding: 10px 15px;
  width: 150px;
  border-radius: 10px;
  text-align: center;
  color: #fff;
  font-family: IranSans;
}

@media screen and (max-width: 600px) {
  .img-top {
    text-align: center;
  }
  .overlay {
    margin-top: 0;
    text-align: center;
  }
  .img-fluid {
    max-width: 95% !important;
    max-height: 50vh !important;
    margin-top: 0 !important;
  }
  h6 {
    line-height: 30px;
  }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
  .img-top {
    text-align: center;
  }
  .overlay {
    margin-top: 50px;
    text-align: center;
  }
  .img-fluid {
    max-width: 95% !important;
    max-height: 55vh !important;
    margin-top: 0 !important;
  }
  h6 {
    line-height: 30px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1050px) {
  .overlay {
    position: absolute;
    top: 70px;
    right: 160px;
  }
  .reg-btn {
    position: absolute;
    top: 30px;
    right: 110px;
  }
  .mt-100 {
    margin-top: 0;
  }
}

@media screen and (min-width: 1310px) and (max-width: 1390px) {
  .overlay {
    position: absolute;
    top: 80px;
    right: 160px;
  }
  .reg-btn {
    position: absolute;
    top: 50px;
    right: 210px;
  }
}

@media screen and (min-width: 1300px) and (min-width: 1430px) {
  h6 {
    line-height: 60px;
  }
  .reg-btn {
    position: absolute;
    top: 150px;
    right: 210px;
  }
}

@media screen and (min-width: 1430px) and (max-width: 1610px) {
  .reg-btn {
    position: absolute;
    top: 130px;
    right: 150px;
  }
}

.after-ip::after {
  content: "";
  position: absolute;
  width: 91px;
  height: 12px;
  bottom: -10px;
  left: 50%;
  z-index: -1;
  -webkit-transform: translate(-45px);
  -ms-transform: translate(-45px);
  transform: translate(-45px);
  background-color: orange;
  -webkit-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url(../assets/images/headline-curve.svg);
  mask-image: url(../assets/images/headline-curve.svg);
}

.box-of-info {
  position: absolute;
  width: 250px;
  height: 80px;
  border-radius: 10px;
  background: #fff;
  top: 50%;
}

.fa-star::before {
  color: #f7c04d;
}

@media screen and (max-width: 768px) {
  .box-of-info {
    position: absolute;
    width: 210px;
    height: 70px;
    border-radius: 10px;
    background: #fff;
    top: 140px;
    right: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .box-of-info {
    position: absolute;
    width: 230px;
    height: 80px;
    border-radius: 10px;
    background: #fff;
    top: 170px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .box-of-info {
    position: absolute;
    width: 270px;
    height: 90px;
    border-radius: 10px;
    background: #fff;
    top: 38%;
  }
}

.parent-of-star {
  position: absolute;
  background: rgb(108, 189, 126);
  width: 50px;
  height: 50px;
  top: -45px;
  right: -30px;
  border-radius: 50%;
  padding: 0 5px;
}

.buy-btn:hover {
  transition: 0.5s all;
  background-color: #ffc221 !important;
  border: none !important;
}

.row {
  min-height: 50vh;
}

@media screen and (min-width: 1000px) {
  .overlay {
    position: absolute;
    top: 110px;
    right: 160px;
  }
  .reg-btn {
    position: absolute;
    top: 100px;
    right: 210px;
  }
  .row {
    min-height: 50vh;
  }
  h6 {
    line-height: 45px;
  }
}

@media screen and (min-width: 801px) and (max-width: 1000px) {
  .overlay {
    position: absolute;
    top: 140px;
    right: 0;
  }
  .reg-btn {
    position: absolute;
    top: 200px;
    right: 210px;
  }
  .row {
    min-height: 50vh;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .row {
    min-height: 55vh;
  }
}

@media screen and (min-width: 1400px) {
  .row {
    min-height: 65vh;
  }
}
</style>
