export default {
  data() {
    return {
      isTablet: false,
      isMobile: false,
      betweenTabletAndDesktop: false,
    };
  },
  computed: {
    mobileView() {
      return this.isMobile;
    },
    tabletView() {
      return this.isTablet;
    },
  },
  methods: {
    onResize() {
      if (window.innerWidth < 769) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      if (window.innerWidth > 769 && window.innerWidth < 1200) {
        this.isTablet = true;
      } else {
        this.isTablet = false;
      }
      if (window.innerWidth < 1200 && window.innerWidth > 1000) {
        this.betweenTabletAndDesktop = true;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize());
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
};
