<template>
  <div
    id="buyTutModal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header d-flex justify-content-center">
          <h4 class="modal-title">اطلاعیه پشتیبانی</h4>
        </div>

        <!-- Modal body -->
        <div
          style="text-align: right; direction: rtl !important"
          class="modal-body"
        >
          <video style="width:100%" height="240" controls>
            <source
              src="https://silkroadway.s3.ir-thr-at1.arvanstorage.ir/weswap.mp4"
              type="video/mp4"
            />
          </video>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer" style="margin: 0; width: 100% !important">
          <button
            type="button"
            class="btn btn-success h-60 w-100"
            data-bs-dismiss="modal"
            @click="pauseVideo"
          >
            باشه
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    pauseVideo(){
        document.querySelector('video').pause();
    }
  },
};
</script>

<style scoped>
.modal-content {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
</style>
