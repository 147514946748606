<template>
  <div class="container-fluid bg-dark d-none-in-mobile" dir="rtl">
    <div class="container">
      <div class="d-flex justify-content-between">
        <div class="color-white">
          {{ notification }}
        </div>
        <div>
          <a href="mailto:info@cloakify.site" class="info-link">
            <i class="info-icon far fa-envelope mail"></i>

            <span class="info-text">info@cloakify.site</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      topNotification: [],
    };
  },
  computed: {
    notification() {
      if (this.topNotification.length) {
        return this.topNotification[0].text;
      } else {
        return "";
      }
    },
  },
  mounted() {
    if (this.$store.state.topNotification.length) {
      this.topNotification = this.$store.state.topNotification;
    } else {
      axios.get("top-notification").then((response) => {
        this.$store.commit("SET_TOP_NOTIF", response.data.data);
        this.topNotification = response.data.data;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.bg-dark {
  background: #031f42 !important;
  padding: 20px 0;
  color: #fff;
}
.info-link {
  vertical-align: middle;
  color: #ffc221;
}
.mail {
  color: #ffc221;
  margin-left: 10px;
  margin-right: 10px;
}

.info-text {
  vertical-align: top;
}

@media screen and (max-width: 768px) {
  .d-none-in-mobile {
    display: none;
  }
}
</style>
