<template>
  <div
    v-if="!order"
    class="container-fluid d-flex justify-content-center align-items-center"
  >
    <div class="row d-flex justify-content-center align-items-center">
      <div class="loader col-12"></div>
      <div class="col-12"><p>در حال دریافت اطلاعات</p></div>
    </div>
  </div>
  <div v-else class="container-fluid">
    <button
      id="clickModalConfirmTrx"
      ref="clickModalConfirmTrx"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#modalConfirmTrx"
    ></button>
    <div
      id="modalConfirmTrx"
      class="modal fade"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header d-flex justify-content-center">
            <h4 class="modal-title">درگاه وی سواپ</h4>
          </div>

          <!-- Modal body -->
          <div
            style="text-align: right; direction: rtl !important"
            class="modal-body"
          >
            <p>
              تایید پرداخت های درگاه وی سواپ معمولا 10 الی 15 دقیقه بعد از
              پرداخت انجام میشود
            </p>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer" style="margin: 0; width: 100% !important">
            <button
              type="button"
              class="btn btn-success h-60 w-100"
              data-bs-dismiss="modal"
              @click="goToPaymentGatewayByTrx"
            >
              باشه
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
      id="clickModalCode"
      ref="clickModalCode"
      @click="ww"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#modalCode"
    ></button>
    <modalCode ref="modalCode"></modalCode>

    <button
      id="clickModalPaymentMethod"
      ref="clickModalPaymentMethod"
      @click="ww"
      style="display: none"
      data-bs-toggle="modal"
      data-bs-target="#modalPaymentMethod"
    ></button>

    <div
      id="modalPaymentMethod"
      class="modal fade show"
      tabindex="-1"
      aria-labelledby="textToCopy"
      aria-modal="true"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="padding: 20px !important">
          <div class="modal-body text-center">
            <h5 style="font-size: 17px" class="modal-title">
              <p style="color: #1b53bcf5; font-size: 21px !important">
                برای اتصال به درگاه پرداخت
                <span style="color: red">فیلترشکن</span> خود را
                <span style="color: red">خاموش</span> کنید
              </p>

              <p class="text-warning">
                در صورت داشتن مشکل در پرداخت برای دریافت لینک موقت به پشتیبانی
                پیام دهید
              </p>

              <p>
                <a
                  style="color: rgb(91, 138, 44) !important"
                  target="_blank"
                  href="tg://proxy?server=65.109.178.122&port=443&secret=ee44947676486d0a4d305981446f1197a07777772e7370656564746573742e6e6574"
                >
                  برای دریافت رایگان پروکسی تلگرام کلیک کنید
                </a>
              </p>
              <hr class="mb-3" />
              <form ref="form" autocomplete="off">
                <div class="mb-3">
                  <input
                    value="siz"
                    class="form-check-input"
                    type="radio"
                    name="paymentMethod"
                    id="flexRadioDefault1"
                    :disabled="is_rial == 2"
                    v-model="payment_method"
                  />
                  <label class="form-check-label" for="flexRadioDefault1">
                    درگاه پرداخت سیزپی
                  </label>
                </div>
                <div class="mt-4">
                  <input
                    value="wallet"
                    class="form-check-input"
                    type="radio"
                    name="paymentMethod"
                    id="flexRadioDefault5"
                    v-model="payment_method"
                  />
                  <label class="form-check-label" for="flexRadioDefault5">
                    پرداخت با کیف پول
                  </label>
                </div>
                <div class="mt-4">
                  <input
                    value="trx"
                    class="form-check-input"
                    type="radio"
                    name="paymentMethod"
                    id="flexRadioDefault3"
                    v-model="payment_method"
                    :disabled="is_rial == 1"
                  />
                  <label class="form-check-label" for="flexRadioDefault3">
                    درگاه پرداخت چنجتو (10درصد تخفیف)
                  </label>
                </div>
                <div class="mt-4" v-if="role == 'admin'">
                  <input
                    value="jib"
                    class="form-check-input"
                    type="radio"
                    name="paymentMethod"
                    id="flexRadioDefault4"
                    v-model="payment_method"
                  />
                  <label class="form-check-label" for="flexRadioDefault4">
                    درگاه پرداخت جیبیمو
                  </label>
                </div>
              </form>
            </h5>
          </div>
          <div
            class="modal-footer"
            style="
              width: 100%;
              justify-content: space-between;
              padding: 10px 0px 5px 0px !important;
            "
          >
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              style="width: 100px; height: 45px"
              ref="closePayModal"
            >
              انصراف
            </button>
            <button
              type="button"
              class="btn btn-success"
              style="width: 100px; height: 45px"
              @click="pay"
            >
              پرداخت
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row table-responsive">
      <table class="table table-striped table-bordered">
        <thead>
          <tr v-if="!mobileView">
            <th class="p-3">نوع</th>
            <th class="p-3">تعداد</th>
            <th class="p-3">قیمت</th>
            <th class="p-3">دریافت کد</th>
          </tr>
          <tr v-else>
            <th class="p-3">نوع</th>
            <th class="p-3">دریافت کد</th>
            <th class="p-3">قیمت</th>
            <th class="p-3">تعداد</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(detail, index) in order.details" :key="index">
            <td class="p-3">{{ detail.product.title }}</td>
            <td v-if="!mobileView" class="p-3">1</td>
            <td v-else class="p-3">
              <a v-if="detail.code == 'منقضی شده'" class="text-danger">
                منقضی شده
              </a>
              <a v-else-if="order.status != 'پرداخت موفق'" class="text-danger">
                پرداخت نشده
              </a>
              <button
                v-else
                @click="showCode(detail.code)"
                class="btn text-white btn-info"
              >
                دریافت
              </button>
            </td>
            <td class="p-3">{{ detail.product.price }}</td>
            <td v-if="!mobileView" class="p-3">
              <a v-if="detail.code == 'منقضی شده'" class="text-danger">
                منقضی شده
              </a>
              <a
                v-else-if="
                  order.status != 'پرداخت موفق' && !order.is_now_payment
                "
                class="text-danger"
              >
                پرداخت نشده
              </a>
              <a
                v-else-if="
                  order.status != 'پرداخت موفق' && order.is_now_payment
                "
                class="text-danger"
              >
                در انتظار تایید
              </a>
              <button
                v-else
                @click="showCode(detail.code)"
                class="btn text-white btn-info"
              >
                دریافت
              </button>
            </td>
            <td v-else class="p-3">1</td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex align-items-center justify-content-between p-0">
        <div
          v-if="!order.used_voucher && order.status != 'پرداخت نشده'"
          class="d-flex"
        >
          <input
            v-model="code"
            class="form-control h-30"
            placeholder="کد تخفیف"
            type="text"
          />
          <button @click="useVoucher" class="btn btn-info mx-2 h-30 text-white">
            اعمال
          </button>
        </div>
        <div v-else></div>
        <div class="text-start">
          <p
            :class="order.used_voucher ? 'has-offer' : ''"
            :style="order.used_voucher ? 'color:red' : 'color: #93750a'"
          >
            جمع کل :
            <span v-if="!order.price_before_voucher">
              {{
                order.total_price ? order.total_price.toLocaleString() : ""
              }}</span
            >
            <span v-else>
              {{
                order.price_before_voucher
                  ? order.price_before_voucher.toLocaleString()
                  : ""
              }}</span
            >
            تومان
          </p>
          <p
            v-if="order.used_voucher"
            :style="order.used_voucher ? 'color:#93750a' : 'color: #93750a'"
          >
            جمع کل بعد از تخفیف:
            <span>{{
              order.total_price ? order.total_price.toLocaleString() : ""
            }}</span>
            تومان
          </p>
          <div class="text-start">
            <p v-if="order.status == 'پرداخت نشده'" style="color: red">
              زمان پرداخت گذشته
            </p>
            <p v-else-if="order.status == 'پرداخت موفق'" style="color: green">
              پرداخت موفق
            </p>
            <div v-else>
              <button
                v-if="role != 'admin'"
                @click="showPaymentMethodSelectModal"
                class="btn btn-success h-30 text-white"
              >
                پرداخت
              </button>

              <button
                v-if="role == 'admin'"
                @click="showPaymentMethodSelectModal"
                class="btn btn-success h-30 text-white"
              >
                پرداخت
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import notification from "@/mixins/notification.js";
import errorReturner from "@/mixins/errorReturner.js";
import translateDigitMxin from "@/mixins/translateDigitMxin.js";
import modalCode from "@/components/order/modalCode.vue";
import paymentMixin from "@/mixins/paymentMixin.js";
import mobileViewMixin from "@/mixins/mobileViewMixin";

export default {
  mixins: [
    translateDigitMxin,
    notification,
    errorReturner,
    paymentMixin,
    mobileViewMixin,
  ],
  data() {
    return {
      order: null,
      code: "",
      is_rial: "",
      role: "",
      payment_method: "",
      userBalance: 0,
      dollarPrice: 0,
    };
  },
  components: {
    modalCode,
  },
  computed: {
    userBalanceToToman() {
      return this.userBalance * this.dollarPrice;
    },
  },
  methods: {
    showPaymentMethodSelectModal() {
      this.$refs.form.reset();
      this.$refs.clickModalPaymentMethod.click();
    },
    goToPaymentGateway() {
      this.redirectToGateway(this.order.id, this.payment_method);
    },
    goToPaymentGatewayByTrx() {
      this.goToPaymentGateway();
    },
    pay() {
      if (this.payment_method) {
        if (this.payment_method == "wallet") {
          let self = this;
          this.$refs.closePayModal.click();
          setTimeout(() => {
            if (self.userBalance * self.dollarPrice >= self.order.total_price) {
              axios
                .post("pay-by-wallet", {
                  orderId: self.order.id,
                })
                .then((res) => {
                  self.userBalance = res.data.data.wallet.balance;
                  self.dollarPrice = res.data.data.dollar;
                  self.order = res.data.data.order;

                  self.successNotification({
                    message: "پرداخت با موفقیت انجام شد",
                  });

                  return;
                })
                .catch((error) => {
                  self.errorNotif = self.errorMaker(error);
                  self.errorNotification(self.errorNotif);

                  return;
                });
            } else {
              this.errorNotification("موجودی ناکافی");

              return;
            }
          }, 100);
        } else if (this.payment_method == "trx") {
          let self = this;
          this.$refs.closePayModal.click();
          setTimeout(() => {
            self.$refs.clickModalConfirmTrx.click();
          }, 100);
        } else {
          this.goToPaymentGateway();
        }
      }
    },
    useVoucher() {
      if (!this.code) {
        return;
      }
      axios
        .post("orders/" + this.order.id + "/voucher", {
          code: this.code,
        })
        .then((response) => {
          this.order = response.data.data;
          this.successNotification({
            message: "با موفقیت اعمال شد",
          });
        })
        .catch((error) => {
          this.errorNotif = this.errorMaker(error);
          this.errorNotification(this.errorNotif);
        });
    },
    showCode(code) {
      this.$refs.modalCode.setCode(code);
      this.$refs.clickModalCode.click();
    },
  },
  mounted() {
    this.role = JSON.parse(localStorage.getItem("silkroad_user")).data.role;
    axios.get("allowable-payment-methods").then((res) => {
      this.is_rial = res.data.is_ria;
      axios.get("/orders/" + this.$route.params.id).then((response) => {
        this.order = response.data.data;
        axios
          .get("get-user-wallet")
          .then((res) => {
            this.userBalance = res.data.data.wallet.balance;
            this.dollarPrice = res.data.data.dollar;
          })
          .catch((error) => {
            this.errorNotif = this.errorMaker(error);
            this.errorNotification(this.errorNotif);
          });
      });
    });
  },
};
</script>

<style scoped>
.has-offer {
  text-decoration-line: line-through;
  text-decoration-color: red;
}
</style>
