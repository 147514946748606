<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6 mt-3">
        <label for="first_name">نام *</label>
        <input
          v-model="user.first_name"
          id="first_name"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-12 col-md-6 mt-3">
        <label for="last_name">نام خانوادگی *</label>
        <input
          v-model="user.last_name"
          id="last_name"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-12 col-md-6 mt-3">
        <label for="full_name">نام نمایشی *</label>
        <input
          v-model="user.name"
          id="full_name"
          type="text"
          class="form-control"
        />
        <label style="font-size: 12px" for="">
          به این ترتیب نام شما در بخش حساب و در بررسی ها نمایش داده می شود
        </label>
      </div>
      <div class="col-12 col-md-6 mt-3">
        <label for="email">آدرس ایمیل *</label>
        <input
          v-model="user.email"
          id="email"
          type="email"
          class="form-control"
        />
      </div>
      <div class="col-12 mt-3 d-flex justify-content-center">
        <button @click="updateUser" class="btn btn-buy btn-primary">
          ذخیره تغییرات
        </button>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <div class="card p-0">
          <div class="card-header">تغییر رمز عبور</div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 mt-3">
                <label for="old_password">رمز عبور فعلی</label>
                <input
                  v-model="old_password"
                  id="old_password"
                  type="password"
                  class="form-control"
                />
              </div>
              <div class="col-12 mt-3">
                <label for="password">رمز عبور جدید</label>
                <input
                  v-model="password"
                  id="password"
                  type="password"
                  class="form-control"
                />
              </div>
              <div class="col-12 mt-3">
                <label for="repeat_password"
                  >رمز عبور جدید را تأیید کنید
                </label>
                <input
                  v-model="repeat_password"
                  id="repeat_password"
                  type="password"
                  class="form-control"
                />
              </div>
              <div class="col-12 mt-3 d-flex justify-content-center">
                <button @click="changePassword" class="btn btn-buy btn-primary">
                  تغییر رمز عبور
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import notification from "@/mixins/notification.js";
import errorReturner from "@/mixins/errorReturner.js";

export default {
  mixins: [notification, errorReturner],
  data() {
    return {
      user: {},
      password: "",
      old_password: "",
      repeat_password: "",
    };
  },
  methods: {
    updateUser() {
      axios
        .post("/user-update", {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email,
          name: this.user.name,
        })
        .then((response) => {
          this.successNotification({
            message: "باموفقیت ثبت شد",
          });
        })
        .catch((error) => {
          this.errorNotif = this.errorMaker(error);
          this.errorNotification(this.errorNotif);
        });
    },
    changePassword() {
      if (!this.password || !this.repeat_password || !this.old_password) {
        this.errorNotification(
          this.errorMaker({
            response: {
              data: {
                message: "لطفا همه موارد رو پر کنید",
              },
            },
          })
        );
        return;
      }
      if (this.password !== this.repeat_password) {
        this.errorNotification(
          this.errorMaker({
            response: {
              data: {
                message: "رمز عبور و تکرار رمز باید یکسان باشند",
              },
            },
          })
        );
        return;
      }

      axios
        .post("/change-password", {
          old_password: this.old_password,
          password: this.password,
        })
        .then((response) => {
          this.successNotification({
            message: "رمز عبور با موفقیت ویرایش شد",
          });
          this.old_password = "";
          this.password = "";
          this.repeat_password = "";
        })
        .catch((error) => {
          this.errorNotif = this.errorMaker(error);
          this.errorNotification(this.errorNotif);
        });
    },
  },
  mounted() {
    axios.get("/user").then((response) => {
      this.user = response.data;
    });
  },
};
</script>

<style scoped>
input,
button {
  height: 45px;
}
</style>
