import * as moment from 'jalali-moment';

export default {
  data() {
    return {
      JalaliDate: {
        g_days_in_month: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
        j_days_in_month: [31, 31, 31, 31, 31, 31, 30, 30, 30, 30, 30, 29],
      },
    };
  },
  methods: {
    convertJalaliToGeorgian(j_y, j_m, j_d) {
      j_y = parseInt(j_y);
      j_m = parseInt(j_m);
      j_d = parseInt(j_d);
      var jy = j_y - 979;
      var jm = j_m - 1;
      var jd = j_d - 1;

      var j_day_no =
        365 * jy + parseInt(jy / 33) * 8 + parseInt(((jy % 33) + 3) / 4);
      for (var i = 0; i < jm; ++i)
        j_day_no += this.JalaliDate.j_days_in_month[i];

      j_day_no += jd;

      var g_day_no = j_day_no + 79;

      var gy =
        1600 +
        400 *
          parseInt(
            g_day_no / 146097,
          ); /* 146097 = 365*400 + 400/4 - 400/100 + 400/400 */
      g_day_no = g_day_no % 146097;

      var leap = true;
      if (g_day_no >= 36525) {
        /* 36525 = 365*100 + 100/4 */
        g_day_no--;
        gy +=
          100 *
          parseInt(g_day_no / 36524); /* 36524 = 365*100 + 100/4 - 100/100 */
        g_day_no = g_day_no % 36524;

        if (g_day_no >= 365) g_day_no++;
        else leap = false;
      }

      gy += 4 * parseInt(g_day_no / 1461); /* 1461 = 365*4 + 4/4 */
      g_day_no %= 1461;

      if (g_day_no >= 366) {
        leap = false;

        g_day_no--;
        gy += parseInt(g_day_no / 365);
        g_day_no = g_day_no % 365;
      }

      for (
        var i = 0;
        g_day_no >= this.JalaliDate.g_days_in_month[i] + (i == 1 && leap);
        i++
      )
        g_day_no -= this.JalaliDate.g_days_in_month[i] + (i == 1 && leap);
      var gm = i + 1;
      var gd = g_day_no + 1;

      gm = gm < 10 ? '0' + gm : gm;
      gd = gd < 10 ? '0' + gd : gd;

      return [gy, gm, gd];
    },
    translateDigitsToPersian(str) {
      if (str) {
        str = str.toString();
        return str.replace(/\d/g, (d) => '۰۱۲۳۴۵۶۷۸۹'[d]);
      }
    },
    translateDigitsToEnglish(str) {
      let persianNumbers = [
          /۰/g,
          /۱/g,
          /۲/g,
          /۳/g,
          /۴/g,
          /۵/g,
          /۶/g,
          /۷/g,
          /۸/g,
          /۹/g,
        ],
        arabicNumbers = [
          /٠/g,
          /١/g,
          /٢/g,
          /٣/g,
          /٤/g,
          /٥/g,
          /٦/g,
          /٧/g,
          /٨/g,
          /٩/g,
        ];
      if (typeof str === 'string') {
        for (let i = 0; i < 10; i++) {
          str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
        }
        return str;
      } else {
        return str;
      }
    },
    persianDateTime(date) {
      if (date) {
        let returnTime = new Date(date).toLocaleDateString('fa-IR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          formatMatcher: 'basic',
        });

        let dataToArray = returnTime.split('،').map((item) => item.trim());
        return dataToArray.join('\n');
      } else {
        return '';
      }
    },
    persianDateTimeDevices(date) {
      if (date) {
        let returnTime = new Date(date).toLocaleDateString('fa-IR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          formatMatcher: 'basic',
        });

        let dataToArray = returnTime.split('،').map((item) => item.trim());
        return dataToArray;
      } else {
        return '';
      }
    },
    persianDate(date) {
      if (date) {
        return moment(date).locale('fa').format('YYYY/M/D');
      } else {
        return '';
      }
    },
    getPersianDay(date, format = null) {
      let week = new Array(
        'يكشنبه',
        'دوشنبه',
        'سه شنبه',
        'چهارشنبه',
        'پنج شنبه',
        'جمعه',
        'شنبه',
      );
      let months = new Array(
        'فروردين',
        'ارديبهشت',
        'خرداد',
        'تير',
        'مرداد',
        'شهريور',
        'مهر',
        'آبان',
        'آذر',
        'دي',
        'بهمن',
        'اسفند',
      );
      let today = new Date(date);
      let d = today.getDay();
      let day = today.getDate();
      let month = today.getMonth() + 1;
      let year = today.getYear();
      year =
        window.navigator.userAgent.indexOf('MSIE') > 0 ? year : 1900 + year;
      if (year == 0) {
        year = 2000;
      }
      if (year < 100) {
        year += 1900;
      }
      let y = 1;
      for (let i = 0; i < 3000; i += 4) {
        if (year == i) {
          y = 2;
        }
      }
      for (let i = 1; i < 3000; i += 4) {
        if (year == i) {
          y = 3;
        }
      }
      if (y == 1) {
        year -= month < 3 || (month == 3 && day < 21) ? 622 : 621;
        switch (month) {
          case 1:
            day < 21
              ? ((month = 10), (day += 10))
              : ((month = 11), (day -= 20));
            break;
          case 2:
            day < 20
              ? ((month = 11), (day += 11))
              : ((month = 12), (day -= 19));
            break;
          case 3:
            day < 21 ? ((month = 12), (day += 9)) : ((month = 1), (day -= 20));
            break;
          case 4:
            day < 21 ? ((month = 1), (day += 11)) : ((month = 2), (day -= 20));
            break;
          case 5:
          case 6:
            day < 22
              ? ((month -= 3), (day += 10))
              : ((month -= 2), (day -= 21));
            break;
          case 7:
          case 8:
          case 9:
            day < 23 ? ((month -= 3), (day += 9)) : ((month -= 2), (day -= 22));
            break;
          case 10:
            day < 23 ? ((month = 7), (day += 8)) : ((month = 8), (day -= 22));
            break;
          case 11:
          case 12:
            day < 22 ? ((month -= 3), (day += 9)) : ((month -= 2), (day -= 21));
            break;
          default:
            break;
        }
      }
      if (y == 2) {
        year -= month < 3 || (month == 3 && day < 20) ? 622 : 621;
        switch (month) {
          case 1:
            day < 21
              ? ((month = 10), (day += 10))
              : ((month = 11), (day -= 20));
            break;
          case 2:
            day < 20
              ? ((month = 11), (day += 11))
              : ((month = 12), (day -= 19));
            break;
          case 3:
            day < 20 ? ((month = 12), (day += 10)) : ((month = 1), (day -= 19));
            break;
          case 4:
            day < 20 ? ((month = 1), (day += 12)) : ((month = 2), (day -= 19));
            break;
          case 5:
            day < 21 ? ((month = 2), (day += 11)) : ((month = 3), (day -= 20));
            break;
          case 6:
            day < 21 ? ((month = 3), (day += 11)) : ((month = 4), (day -= 20));
            break;
          case 7:
            day < 22 ? ((month = 4), (day += 10)) : ((month = 5), (day -= 21));
            break;
          case 8:
            day < 22 ? ((month = 5), (day += 10)) : ((month = 6), (day -= 21));
            break;
          case 9:
            day < 22 ? ((month = 6), (day += 10)) : ((month = 7), (day -= 21));
            break;
          case 10:
            day < 22 ? ((month = 7), (day += 9)) : ((month = 8), (day -= 21));
            break;
          case 11:
            day < 21 ? ((month = 8), (day += 10)) : ((month = 9), (day -= 20));
            break;
          case 12:
            day < 21 ? ((month = 9), (day += 10)) : ((month = 10), (day -= 20));
            break;
          default:
            break;
        }
      }
      if (y == 3) {
        year -= month < 3 || (month == 3 && day < 21) ? 622 : 621;
        switch (month) {
          case 1:
            day < 20
              ? ((month = 10), (day += 11))
              : ((month = 11), (day -= 19));
            break;
          case 2:
            day < 19
              ? ((month = 11), (day += 12))
              : ((month = 12), (day -= 18));
            break;
          case 3:
            day < 21 ? ((month = 12), (day += 10)) : ((month = 1), (day -= 20));
            break;
          case 4:
            day < 21 ? ((month = 1), (day += 11)) : ((month = 2), (day -= 20));
            break;
          case 5:
          case 6:
            day < 22
              ? ((month -= 3), (day += 10))
              : ((month -= 2), (day -= 21));
            break;
          case 7:
          case 8:
          case 9:
            day < 23 ? ((month -= 3), (day += 9)) : ((month -= 2), (day -= 22));
            break;
          case 10:
            day < 23 ? ((month = 7), (day += 8)) : ((month = 8), (day -= 22));
            break;
          case 11:
          case 12:
            day < 22 ? ((month -= 3), (day += 9)) : ((month -= 2), (day -= 21));
            break;
          default:
            break;
        }
      }
      if (format === null || format === undefined) return `${week[d]}`;
      if (format === 'y/m/d') return `${year}/${month}/${day}`;
      if (format === 'd/m/y') return `${day}/${month}/${year}`;
    },
    getPersianDate(date, format = null) {
      let week = new Array(
        'يكشنبه',
        'دوشنبه',
        'سه شنبه',
        'چهارشنبه',
        'پنج شنبه',
        'جمعه',
        'شنبه',
      );
      let months = new Array(
        'فروردين',
        'ارديبهشت',
        'خرداد',
        'تير',
        'مرداد',
        'شهريور',
        'مهر',
        'آبان',
        'آذر',
        'دي',
        'بهمن',
        'اسفند',
      );
      let today = new Date(date);
      let d = today.getDay();
      let day = today.getDate();
      let month = today.getMonth() + 1;
      let year = today.getYear();
      year =
        window.navigator.userAgent.indexOf('MSIE') > 0 ? year : 1900 + year;
      if (year == 0) {
        year = 2000;
      }
      if (year < 100) {
        year += 1900;
      }
      let y = 1;
      for (let i = 0; i < 3000; i += 4) {
        if (year == i) {
          y = 2;
        }
      }
      for (let i = 1; i < 3000; i += 4) {
        if (year == i) {
          y = 3;
        }
      }
      if (y == 1) {
        year -= month < 3 || (month == 3 && day < 21) ? 622 : 621;
        switch (month) {
          case 1:
            day < 21
              ? ((month = 10), (day += 10))
              : ((month = 11), (day -= 20));
            break;
          case 2:
            day < 20
              ? ((month = 11), (day += 11))
              : ((month = 12), (day -= 19));
            break;
          case 3:
            day < 21 ? ((month = 12), (day += 9)) : ((month = 1), (day -= 20));
            break;
          case 4:
            day < 21 ? ((month = 1), (day += 11)) : ((month = 2), (day -= 20));
            break;
          case 5:
          case 6:
            day < 22
              ? ((month -= 3), (day += 10))
              : ((month -= 2), (day -= 21));
            break;
          case 7:
          case 8:
          case 9:
            day < 23 ? ((month -= 3), (day += 9)) : ((month -= 2), (day -= 22));
            break;
          case 10:
            day < 23 ? ((month = 7), (day += 8)) : ((month = 8), (day -= 22));
            break;
          case 11:
          case 12:
            day < 22 ? ((month -= 3), (day += 9)) : ((month -= 2), (day -= 21));
            break;
          default:
            break;
        }
      }
      if (y == 2) {
        year -= month < 3 || (month == 3 && day < 20) ? 622 : 621;
        switch (month) {
          case 1:
            day < 21
              ? ((month = 10), (day += 10))
              : ((month = 11), (day -= 20));
            break;
          case 2:
            day < 20
              ? ((month = 11), (day += 11))
              : ((month = 12), (day -= 19));
            break;
          case 3:
            day < 20 ? ((month = 12), (day += 10)) : ((month = 1), (day -= 19));
            break;
          case 4:
            day < 20 ? ((month = 1), (day += 12)) : ((month = 2), (day -= 19));
            break;
          case 5:
            day < 21 ? ((month = 2), (day += 11)) : ((month = 3), (day -= 20));
            break;
          case 6:
            day < 21 ? ((month = 3), (day += 11)) : ((month = 4), (day -= 20));
            break;
          case 7:
            day < 22 ? ((month = 4), (day += 10)) : ((month = 5), (day -= 21));
            break;
          case 8:
            day < 22 ? ((month = 5), (day += 10)) : ((month = 6), (day -= 21));
            break;
          case 9:
            day < 22 ? ((month = 6), (day += 10)) : ((month = 7), (day -= 21));
            break;
          case 10:
            day < 22 ? ((month = 7), (day += 9)) : ((month = 8), (day -= 21));
            break;
          case 11:
            day < 21 ? ((month = 8), (day += 10)) : ((month = 9), (day -= 20));
            break;
          case 12:
            day < 21 ? ((month = 9), (day += 10)) : ((month = 10), (day -= 20));
            break;
          default:
            break;
        }
      }
      if (y == 3) {
        year -= month < 3 || (month == 3 && day < 21) ? 622 : 621;
        switch (month) {
          case 1:
            day < 20
              ? ((month = 10), (day += 11))
              : ((month = 11), (day -= 19));
            break;
          case 2:
            day < 19
              ? ((month = 11), (day += 12))
              : ((month = 12), (day -= 18));
            break;
          case 3:
            day < 21 ? ((month = 12), (day += 10)) : ((month = 1), (day -= 20));
            break;
          case 4:
            day < 21 ? ((month = 1), (day += 11)) : ((month = 2), (day -= 20));
            break;
          case 5:
          case 6:
            day < 22
              ? ((month -= 3), (day += 10))
              : ((month -= 2), (day -= 21));
            break;
          case 7:
          case 8:
          case 9:
            day < 23 ? ((month -= 3), (day += 9)) : ((month -= 2), (day -= 22));
            break;
          case 10:
            day < 23 ? ((month = 7), (day += 8)) : ((month = 8), (day -= 22));
            break;
          case 11:
          case 12:
            day < 22 ? ((month -= 3), (day += 9)) : ((month -= 2), (day -= 21));
            break;
          default:
            break;
        }
      }
      if (format === null || format === undefined)
        // return `${week[d]} ${day} ${months[month - 1]} ${year}`
        return ` ${day} ${months[month - 1]} ${year}`;
      if (format === 'y/m/d') return `${year}/${month}/${day}`;
      if (format === 'd/m/y') return `${day}/${month}/${year}`;
    },
  },
};
