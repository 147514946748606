<template>
  <button
    id="clickModalHome"
    ref="clickModalHome"
    style="display: none"
    data-bs-toggle="modal"
    data-bs-target="#homeModal"
  ></button>
  <HomeModal></HomeModal>
  <Header
    :headerImg="headerImg"
    :headerH1="headerH1"
    :headerH6="headerH6"
  ></Header>
  <GameTrade></GameTrade>
  <InfoCards></InfoCards>
  <Telegram></Telegram>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import GameTrade from "@/components/index/GameTrade.vue";
import InfoCards from "@/components/index/InfoCards.vue";
import Telegram from "@/components/index/Telegram.vue";
import HomeModal from '@/components/HomeModal.vue';

export default {
  name: "index",
  data() {
    return {
      headerImg: require("@/assets/images/header.png"),
      headerH1: "cloakify",
      headerH6: " فروش آیپی ثابت ",
    };
  },
  components: { Header, GameTrade, InfoCards, Telegram, HomeModal},
  mounted(){
    this.$refs.clickModalHome.click();
  }
};
</script>
