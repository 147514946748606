<template>
  <Heading :thisRouteName="product.title"></Heading>
  <div class="container-fluid px-4" dir="rtl">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8 mt-4 col-lg-9">
          <div class="row g-4">
            <div class="col-12 col-md-6 mt-5">
              <img class="w-100" :src="product.image" alt="" />
            </div>
            <div class="col-12 col-md-6">
              <div class="single-product-card">
                <div class="single-product-card-header mt-5 mb-4">
                  <h2>{{ product.title }}</h2>
                </div>
                <hr />
                <div class="single-product-card-body">
                  <h2 style="font-weight: bold" class="mt-4 mb-4">
                    {{ product.price.toLocaleString() }} تومان
                  </h2>
                  <p class="mt-4 mb-4">{{ product.short_description }}</p>
                  <p class="mt-4 mb-4">
                    تعداد در انبار : {{ product.in_stock }}
                    <span v-if="product.in_stock">
                      <i class="fa fa-check-circle text-success"></i
                    ></span>
                  </p>
                </div>
                <div class="single-product-card-footer">
                  <div class="row">
                    <div class="col-4">
                      <input
                        type="number"
                        class="input-text qty text"
                        v-model="qty"
                        title="تعداد"
                        size="4"
                        min="1"
                        :max="product.in_stock"
                        step="1"
                        inputmode="numeric"
                      />
                    </div>
                    <div class="col-8">
                      <button
                        @click="addToCart(product)"
                        class="btn h-60 btn-buy btn-primary w-100"
                      >
                        افزودن به سبد خرید
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-3 mt-5">
          <sidebar></sidebar>
        </div>
      </div>

      <div class="row mt-150 p-4">
        <ul class="nav nav-tabs">
          <li class="nav-item mx-3 px-3">
            <a
              @click="selectedNav = 'desc'"
              :class="selectedNav == 'desc' ? 'active' : ''"
              class="nav-link"
              aria-current="page"
              style="font-size: 22px"
              >توضیحات</a
            >
          </li>
          <li class="nav-item mx-3 px-3">
            <a
              @click="selectedNav = 'allDescs'"
              :class="selectedNav == 'allDescs' ? 'active' : ''"
              class="nav-link"
              style="font-size: 22px"
              >توضیحات تکمیلی</a
            >
          </li>
        </ul>
        <div class="content mb-5">
          <p class="mt-5 px-4" v-if="selectedNav == 'desc'">
            {{ product.description }}
          </p>
          <ul class="mt-5 px-4" v-else>
            <li class="d-flex justify-content-between" style="max-width: 300px">
              <span>حجم :</span>
              <span>{{ product.total_descriptions.capacity }}</span>
            </li>
            <li class="d-flex justify-content-between" style="max-width: 300px">
              <span>نیم بهاء :</span>
              <span>{{
                product.total_descriptions.half_price ? "بله" : "خیر"
              }}</span>
            </li>
            <li
              v-if="product.total_descriptions.by_pass_national_net"
              class="d-flex justify-content-between"
              style="max-width: 300px"
            >
              <span>دور زدن نت ملی :</span>
              <span>{{
                product.total_descriptions.by_pass_national_net ? "بله" : "خیر"
              }}</span>
            </li>
            <li class="d-flex justify-content-between" style="max-width: 300px">
              <span>پشتیبانی همه پلتفرم ها :</span>
              <span>
                {{ product.total_descriptions.all_platforms ? "بله" : "خیر" }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "@/components/Heading.vue";
import sidebar from "@/components/product/sidebar.vue";
import notification from "@/mixins/notification.js";
import errorReturner from "@/mixins/errorReturner.js";
import pusher from "../pusher";

export default {
  data() {
    return {
      selectedNav: "desc",
      qty: 1,
      thisProduct: {
        image: "",
        title: "",
        short_description: "",
        total_descriptions: {},
        description: "",
        price: "",
        in_stock: 0,
      },
    };
  },
  mixins: [errorReturner, notification],
  components: {
    Heading,
    sidebar,
  },
  name: "product",
  computed: {
    product() {
      return this.thisProduct;
    },
  },
  methods: {
    addToCart(product) {
      if (this.$store.state.cart.count + this.qty >= 5) {
        this.errorNotification(
          this.errorMaker({
            response: {
              data: {
                message: "ظرفیت سبد خرید شما 5 محصول است",
              },
            },
          })
        );
        return;
      }

      let cart = this.$store.state.cart;

      let foundProduct = cart.products.find((el) => {
        return el.id === product.id;
      });

      if (foundProduct) {
        if (
          foundProduct.qty >= product.in_stock ||
          foundProduct.qty + this.qty >= product.in_stock
        ) {
          this.errorNotification(
            this.errorMaker({
              response: {
                data: {
                  message: "بیش از این مقدار در انبار موجود نیست",
                },
              },
            })
          );
          return;
        }
      }

      if (!foundProduct) {
        if (!product.in_stock) {
          this.errorNotification(
            this.errorMaker({
              response: {
                data: {
                  message: "این محصول در انبار موجود نیست",
                },
              },
            })
          );
          return;
        }
      }

      this.$store.commit("ADD_TO_CART_BY_QTY", { product, qty: this.qty });
      this.successNotification({
        message: "باموفقیت به سبد خرید افزوده شد ",
      });
      this.$router.push({ name: "cart" });
    },
  },
  mounted() {
    this.$store
      .dispatch("getProduct", { id: this.$route.params.id })
      .then((response) => {
        this.thisProduct = response.data;
      });

    let channel = pusher.subscribe("my-channel");

    let self = this;
    channel.bind(
      "App\\Events\\Broadcasts\\ProductStockChanged",
      function (data) {
        data.data.forEach((el) => {
          if (+el.id === +self.$route.params.id) {
            self.thisProduct.in_stock = el.stock
          }
        });
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.qty {
  width: 100% !important;
  height: 60px;
  border: 1px solid #eee;
  background: #eee;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  color: #333;
}
.h-60 {
  height: 60px;
}
.mt-150 {
  margin-top: 100px;
}

a {
  color: #333;
}
a.active {
  color: #0071dc !important;
}
</style>
