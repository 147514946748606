<template>
  <div style="max-height: 650px; overflow-y: auto" class="container-fluid">
    <div class="row table-responsive">
      <table class="table table-striped table-bordered">
        <thead>
          <tr v-if="!mobileView">
            <th class="p-3">کد</th>
            <th class="p-3">تاریخ</th>
            <th class="p-3">نوع</th>
            <th class="p-3">میزان</th>
            <th class="p-3">وضعیت</th>
            <th class="p-3">عملیات</th>
          </tr>
          <tr v-else>
            <th class="p-3">کد</th>
            <th class="p-3">عملیات</th>
            <th class="p-3">نوع</th>
            <th class="p-3">میزان</th>
            <th class="p-3">وضعیت</th>
            <th class="p-3">تاریخ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(voucher, index) in vouchers" :key="index">
            <td class="p-3">{{ voucher.code }}</td>
            <td v-if="!mobileView" class="p-3">
              {{ persianDate(voucher.created_at) }}
            </td>
            <td v-else class="p-3">
              <button
                v-if="!voucher.is_used"
                @click="copyCode(voucher)"
                class="btn mx-1 text-white btn-success"
              >
                کپی کردن
              </button>
            </td>
            <td class="p-3">
              {{ voucher.type == "amount" ? "تومانی" : "درصدی" }}
            </td>
            <td class="p-3">
              <span>{{
                voucher.type == "amount"
                  ? voucher.amount.toLocaleString() + " تومان"
                  : voucher.amount + " %"
              }}</span>
            </td>
            <td class="p-3">
              <span>{{ voucher.is_used ? "استفاده شده" : "دردسترس" }}</span>
            </td>
            <td v-if="!mobileView" class="p-3">
              <button
                v-if="!voucher.is_used"
                @click="copyCode(voucher)"
                class="btn mx-1 text-white btn-success"
              >
                کپی کردن
              </button>
            </td>
            <td v-else class="p-3">
              {{ persianDate(voucher.created_at) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import translateDigitMxin from "@/mixins/translateDigitMxin.js";
import mobileViewMixin from "@/mixins/mobileViewMixin";
import notification from "@/mixins/notification.js";
import errorReturner from "@/mixins/errorReturner.js";

export default {
  mixins: [translateDigitMxin, mobileViewMixin, notification, errorReturner],
  data() {
    return {
      vouchers: [],
    };
  },
  methods: {
    copyCode(voucher) {
      navigator.clipboard.writeText(voucher.code);
      this.successNotification({
        message: "با موفقیت کپی شد",
      });
    },
  },
  mounted() {
    axios.get("/user-vouchers").then((response) => {
      this.vouchers = response.data.data;
    });
  },
};
</script>

<style></style>
