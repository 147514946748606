<template>
  <div style="max-height: 650px; overflow-y: auto" class="container-fluid">
    <div class="row table-responsive">
      <table class="table table-striped table-bordered">
        <thead>
          <tr v-if="!mobileView">
            <th class="p-3">سفارش</th>
            <th class="p-3">تاریخ</th>
            <th class="p-3">وضعیت</th>
            <th class="p-3">مجموع</th>
            <th class="p-3">عملیات</th>
          </tr>
          <tr v-else>
            <th class="p-3">سفارش</th>
            <th class="p-3">عملیات</th>
            <th class="p-3">وضعیت</th>
            <th class="p-3">مجموع</th>
            <th class="p-3">تاریخ</th>
          </tr>
        </thead>
        <tbody>
          <tr
            :style="
              order.status == 'پرداخت موفق'
                ? 'background-color: rgba(0, 255, 0, 0.15);'
                : 'background-color: rgba(255, 0, 0, 0.15);'
            "
            v-for="(order, index) in orders"
            :key="index"
          >
            <td class="p-3">#{{ order.order_number }}</td>
            <td v-if="!mobileView" class="p-3">
              {{
                order.paid_at
                  ? persianDate(order.paid_at)
                  : persianDate(order.created_at)
              }}
            </td>
            <td v-else class="p-3">
              <button
                @click="
                  $router.push({ name: 'order', params: { id: order.id } })
                "
                class="btn mx-1 text-white btn-warning"
              >
                جزییات
              </button>
            </td>
            <td class="p-3">{{ order.status }}</td>
            <td class="p-3">
              <span>{{ order.total_price.toLocaleString() }}</span> تومان برای
              {{ order.count }} مورد
            </td>
            <td v-if="!mobileView" class="p-3">
              <button
                @click="
                  $router.push({ name: 'order', params: { id: order.id } })
                "
                class="btn mx-1 text-white btn-warning"
              >
                جزییات
              </button>
            </td>
            <td v-else class="p-3">
              {{
                order.paid_at
                  ? persianDate(order.paid_at)
                  : persianDate(order.created_at)
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import translateDigitMxin from "@/mixins/translateDigitMxin.js";
import mobileViewMixin from "@/mixins/mobileViewMixin";

export default {
  mixins: [translateDigitMxin, mobileViewMixin],
  data() {
    return {
      orders: [],
    };
  },
  mounted() {
    axios.get("/orders").then((response) => {
      this.orders = response.data.data;
    });
  },
};
</script>

<style></style>
