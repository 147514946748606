<template>
  <main
    class="main-content mt-5 mb-5 d-flex align-items-center"
    style="height: 500px"
    dir="rtl"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>ورود</h5>
            </div>
            <div class="card-body">
              <form role="form" @submit.prevent>
                <div class="row mb-2">
                  <div class="col-12">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="ایمیل و یا نام کاربری"
                      v-model="username"
                    />
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-12">
                    <input
                      type="password"
                      class="form-control"
                      placeholder="رمز"
                      v-model="password"
                    />
                  </div>
                </div>
                <div class="text-center">
                  <button
                    class="my-4 mb-2 w-100 btn btn-primary"
                    @click="login"
                  >
                    ورود
                  </button>
                </div>
                <p class="text-sm mt-3 mb-0">
                  <router-link
                    to="/reset-password"
                    class="text-dark font-weight-bolder"
                  >
                    رمز عبور فراموش کرده اید ؟
                  </router-link>
                </p>
                <p class="text-sm mt-3 mb-0">
                  حساب کاربری ندارید ؟
                  <router-link
                    to="/register"
                    class="text-dark font-weight-bolder"
                    >ثبت نام</router-link
                  >
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import notification from "@/mixins/notification.js";
import errorReturner from "@/mixins/errorReturner.js";
import axios from "axios";

export default {
  mixins: [notification, errorReturner],
  methods: {
    login() {
      if (!this.password || !this.username) {
        this.errorNotification(
          this.errorMaker({
            response: {
              data: {
                message: "لطفا همه موارد رو پر کنید",
              },
            },
          })
        );
        return;
      }
      axios
        .post("/login", {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          this.$store.commit("SET_USER_DATA_AND_TOKEN", response);
          this.successNotification({
            message: "باموفقیت وارد شدید",
          });
          this.$router.push({ name: "main-dashboard" });
        })
        .catch((error) => {
          this.errorNotif = this.errorMaker(error);
          this.errorNotification(this.errorNotif);
        });
    },
  },
};
</script>

<style></style>
