<template>
  <div class="container-fluid py-5">
    <div class="container">
      <div class="row text-center">
        <div class="col-12">
          <img style="height: 60px;" src="@/assets/images/logo.png" alt="" />
        </div>
        <div class="col-12 mt-4">
          <div class="hover-priamry-color">
            <a href="mailto:info@cloakify.site">info@cloakify.site</a>
          </div>
        </div>
        <div class="col-12 mt-4">
          <a
            class="link"
            aria-label="Telegram"
            href="https://t.me/cloakify"
            target="_blank"
            rel="nofollow"
          >
            <i class="link-icon fab fa-telegram-plane"></i>
          </a>
        </div>
      </div>
    </div>
    <hr class="mt-5 mb-5" />

    <p style="color:#9B9B9B" class="text-center">تمامی حقوق برای cloakify محفوظ است. طراحی و اجرا توسط استارک</p>
  </div>
</template>

<style lang="scss" scoped>
.hover-priamry-color:hover > * {
  color: #0071dc;
}
.fa-telegram-plane {
  font-size: 30px;
  color: #6ec0e2;
}
</style>
